import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import selectIsAuthorized from '../../selectors/selectIsAuthorized';
import RegistrationForm from './registrationForm/RegistrationForm';
import LoginRegLayout from '../../layouts/loginRegLayout/LoginRegLayout';
import { AuthFormLayoutTheme, LoginTab } from '../../layouts/authFormLayout/AuthFormLayout';
import { useTranslation } from 'react-i18next';

const RegistrationPage: FunctionComponent = () => {
    const { t } = useTranslation('loginPage');
    const isAuthorized = useSelector(selectIsAuthorized);

    if (isAuthorized) {
        return <>{t('logged_logout')}</>;
    }

    return (
        <LoginRegLayout activeTab={LoginTab.REGISTRATION} theme={AuthFormLayoutTheme.WHITE_BG}>
            <RegistrationForm />
        </LoginRegLayout>
    );
};

export default RegistrationPage;
