import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColor } from '../../../../../../components/button/Button';
import HomePageTitle from '../../../homePageTitle/HomePageTitle';
import TariffFeature, { Feature } from '../tariffOpportunity/TariffFeature';
import getStringKey from '../../../../../../utils/getStringKey';
import { SubscriptionType } from '../../../../../../types/api';
import selectIsAuthorized from '../../../../../../selectors/selectIsAuthorized';
import { useSelector } from 'react-redux';
import AppRoute from '../../../../../../constants/AppRoute';
import styles from './TariffItem.module.scss';

export interface Tariff {
    type: SubscriptionType;
    title: string;
    price: string;
    priceExtra?: string;
    link: string;
    features: Feature[];
}

interface Props {
    tariff: Tariff;
}

const TariffItem: FC<Props> = ({ tariff }) => {
    const { t } = useTranslation('homePage');
    const isAuthorized = useSelector(selectIsAuthorized);

    const tariffLinkLabel =
        tariff.type === SubscriptionType.TRIAL ? t('dashboard_button') : t('tariffs_block.choose_a_tariff');

    const button = (
        <Button
            className={styles.button}
            color={ButtonColor.GREEN_BORDERED}
            link={isAuthorized ? tariff.link : AppRoute.REGISTRATION}
        >
            {isAuthorized ? tariffLinkLabel : t('tariffs_block.register')}
        </Button>
    );

    return (
        <div className={styles.container}>
            <HomePageTitle className={styles.title}>{tariff.title}</HomePageTitle>
            <div className={styles.price}>
                <div className={styles.priceDefault}>{tariff.price}</div>
                {tariff.priceExtra && (
                    <>
                        {' '}
                        <div className={styles.priceExtra}>({tariff.priceExtra})</div>
                    </>
                )}
            </div>
            <div className={styles.content}>
                {tariff.features.map((feature, index) => (
                    <TariffFeature data={feature} key={getStringKey(index, 'tariffFeature')} />
                ))}
            </div>
            {button}
        </div>
    );
};

export default TariffItem;
