import { AppThunkAction } from '../../types/redux';
import { request } from '../../utils/request';
import { User } from '../../types/api';
import { setUser } from '../../redux/reducers/application';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';

const logger = createLogger('fetchUser', ACTIONS_LOG_LEVEL);

const fetchUser = (): AppThunkAction => async (dispatch) => {
    try {
        const user = await request<User>({ url: '/users/me' });
        dispatch(setUser(user));
    } catch (error) {
        logger.error('fetchUser: catch error', error);
        dispatch(setUser(undefined));
    }
};

export default fetchUser;
