/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */

import Logger, { ILogLevel } from 'js-logger';
import { isDevFeaturesEnabled } from '../constants/env';

Logger.useDefaults({ defaultLevel: isDevFeaturesEnabled ? Logger.DEBUG : Logger.WARN });

export const systemLogger = Logger.get('SystemLogger');
export const globalObjectsLogger = Logger.get('GlobalObjectsLogger');
export const devFeaturesLogger = Logger.get('DevFeaturesLogger');

export const UTILS_LOG_LEVEL = isDevFeaturesEnabled ? Logger.DEBUG : Logger.getLevel();
export const ACTIONS_LOG_LEVEL = isDevFeaturesEnabled ? Logger.DEBUG : Logger.getLevel();

if (isDevFeaturesEnabled) {
    console.log(`Default logger level is ${systemLogger.getLevel().name}`, {
        UTILS_LOG_LEVEL: UTILS_LOG_LEVEL.name,
        ACTIONS_LOG_LEVEL: ACTIONS_LOG_LEVEL.name
    });
}

export const createLogger = (name: string, level: ILogLevel = Logger.getLevel()) => {
    const instance = Logger.get(name);
    instance.setLevel(level);

    return instance;
};
