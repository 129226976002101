import { setUser } from '../../redux/reducers/application';
import { ACCESS_TOKEN, post } from '../../utils/request';

const logout = () => async (dispatch) => {
    await post({
        url: '/auth/logout'
    });
    localStorage.removeItem(ACCESS_TOKEN);
    return dispatch(setUser(undefined));
};

export default logout;
