import { FC } from 'react';
import getStringKey from '../../../../../utils/getStringKey';
import styles from './HowToStartItem.module.scss';

export interface HowToStartObject {
    title?: string;
    imgSources: string[];
}

interface Props {
    item: HowToStartObject;
}

const HowToStartItem: FC<Props> = ({ item }) => {
    return (
        <div className={styles.container}>
            {item.title}
            <div className={styles.imagesWrapper}>
                <div className={styles.images}>
                    {item.imgSources.map((imgSource, index) => (
                        <img
                            className={styles.image}
                            src={imgSource}
                            alt={item.title}
                            key={getStringKey(index, 'howToStartImages')}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HowToStartItem;
