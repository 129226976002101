import React, { FC } from 'react';
import Select, { SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as DropdownArrow } from '../forkedSelect/dropdownArrow.svg';
import styles from './LanguageSelector.module.scss';

type OptionType = {
    value: string;
    label: string;
};

interface Props {
    className?: string;
}

const LanguageSelector: FC<Props> = ({ className }) => {
    const { i18n } = useTranslation();

    const languageOptions: OptionType[] = i18n.options.supportedLngs
        .map((lng: string) => ({
            value: lng,
            label: lng.toUpperCase()
        }))
        .filter((lng: OptionType) => lng.value !== 'cimode');

    const handleChangeLanguage = async (selectedOption: SingleValue<OptionType>) => {
        if (selectedOption) {
            try {
                await i18n.changeLanguage(selectedOption.value);
            } catch (error) {
                console.error('Error changing language:', error);
            }
        }
    };

    // Применение импортированных стилей к компоненту Select
    return (
        <Select
            defaultValue={languageOptions.find((option: OptionType) => option.value === i18n.language)}
            options={languageOptions}
            onChange={handleChangeLanguage}
            isSearchable={false}
            className={classNames(styles.selectContainer, className)}
            classNamePrefix="select"
            components={{
                IndicatorSeparator: null,
                DropdownIndicator: ({ isFocused, isDisabled }) => (
                    <div
                        className={classNames(styles.dropdownIndicator, {
                            [styles.dropdownIndicatorFocused]: isFocused,
                            [styles.dropdownIndicatorDisabled]: isDisabled
                        })}
                    >
                        <DropdownArrow />
                    </div>
                )
            }}
        />
    );
};

export default LanguageSelector;
