import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundPage: FC = () => {
    const { t } = useTranslation('common');

    return <>{t('page_not_found')}</>;
};

export default NotFoundPage;
