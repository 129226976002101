import { castImmutable } from 'immer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Order, Pageable } from '../../types/api';
import actionCreator from '../utils/actionCreator';
import immerCase from '../utils/immerCase';

export interface OrderState {
    orders?: Pageable<Order>;
}

const initialState = castImmutable<OrderState>({});

export const setTrades = actionCreator<Pageable<Order> | undefined>('setTrades');

// @ts-ignores
export default reducerWithInitialState<OrderState>(initialState).withHandling(
    immerCase(setTrades, (draft, payload) => {
        draft.orders = payload;
    })
);
