import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import registration from '../../../actions/user/registration';
import AppRoute from '../../../constants/AppRoute';
import { AppThunkDispatch } from '../../../types/redux';
import { REQUIRED_FIELD } from '../../../constants/validation';
import Button, { ButtonColor, ButtonType } from '../../../components/button/Button';
import InnerLink from '../../../components/innerLink/InnerLink';
import classNames from 'classnames';
import inputStyles from '../../../components/loginInput/LoginInput.module.scss';
import styles from './RegistrationForm.module.scss';

interface RegistrationFormValues {
    fullName: string;
    email: string;
    password: string;
    confirm: string;
}

const RegistrationForm: FunctionComponent = () => {
    const { t } = useTranslation('loginPage');
    const dispatch: AppThunkDispatch = useDispatch();
    const navigate = useNavigate();

    const schema = Yup.object().shape({
        fullName: Yup.string().required(REQUIRED_FIELD),

        email: Yup.string().required(REQUIRED_FIELD).email(t('invalid_email')),

        password: Yup.string().required(REQUIRED_FIELD).min(6, t('short_password')),

        confirm: Yup.string()
            .required(REQUIRED_FIELD)
            .min(6, t('short_password'))
            .oneOf([Yup.ref('password')], t('password_mismatch'))
    });

    const form = useForm<RegistrationFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    const {
        register,
        setError,
        formState: { isSubmitting, isValid, errors },
        handleSubmit: onSubmit
    } = form;

    const handleSubmit = useCallback(
        (values: any) => {
            dispatch(registration(values.email, values.password, values.confirm, values.fullName))
                .then(() => navigate(AppRoute.PROFILE))
                .catch((message) => {
                    Object.entries(message).map(([key, value]) => {
                        setError(key as any, { message: value as string });
                    });
                });
        },
        [navigate, dispatch]
    );

    return (
        <>
            <form autoComplete="off" className={styles.form} onSubmit={onSubmit(handleSubmit)}>
                <div className={styles.fields}>
                    <div className={styles.group}>
                        <input
                            className={inputStyles.input}
                            autoComplete="off"
                            placeholder={t('your_name')}
                            aria-invalid={errors.fullName ? 'true' : 'false'}
                            required
                            {...register('fullName')}
                        />
                        {errors.fullName && (
                            <p className={classNames(inputStyles.message, inputStyles.error)}>
                                {errors.fullName.message}
                            </p>
                        )}
                    </div>

                    <div className={styles.group}>
                        <input
                            className={inputStyles.input}
                            autoComplete="off"
                            placeholder={t('email')}
                            aria-invalid={errors.email ? 'true' : 'false'}
                            required
                            {...register('email')}
                        />
                        {errors.email && (
                            <p className={classNames(inputStyles.message, inputStyles.error)}>{errors.email.message}</p>
                        )}
                    </div>

                    <div className={styles.group}>
                        <input
                            autoComplete="off"
                            type="password"
                            className={inputStyles.input}
                            placeholder={t('password')}
                            aria-invalid={errors.password ? 'true' : 'false'}
                            required
                            {...register('password')}
                        />
                        {errors.password && (
                            <p className={classNames(inputStyles.message, inputStyles.error)}>
                                {errors.password.message}
                            </p>
                        )}
                    </div>

                    <div className={styles.group}>
                        <input
                            autoComplete="off"
                            type="password"
                            className={inputStyles.input}
                            placeholder={t('password_confirm')}
                            aria-invalid={errors.confirm ? 'true' : 'false'}
                            required
                            {...register('confirm')}
                        />
                        {errors.confirm && (
                            <p className={classNames(inputStyles.message, inputStyles.error)}>
                                {errors.confirm.message}
                            </p>
                        )}
                    </div>
                </div>

                <Button
                    type={ButtonType.SUBMIT}
                    color={ButtonColor.TRANSPARENT}
                    className={styles.button}
                    disabled={isSubmitting || !isValid}
                >
                    {t('register')}
                </Button>
                <p className={styles.privacy}>
                    {t('agreement')}
                    <InnerLink target="_blank" to={AppRoute.PRIVACY}>
                        {t('agreement_privacy')}
                    </InnerLink>
                </p>
            </form>
        </>
    );
};

export default RegistrationForm;
