import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import coverImg from '../../pages/homePage/components/titleBlock/imgs/cover.png';
import gridImg from '../../pages/homePage/components/titleBlock/imgs/grid.svg';
import styles from './PromoBlock.module.scss';

interface Props extends PropsWithChildren {
    fixedBg?: boolean;
}

const PromoBlock: FC<Props> = ({ children, fixedBg }) => {
    return (
        <div className={classNames(styles.container, { [styles.fixedBg]: fixedBg })}>
            <div className={styles.textContainer}>{children}</div>
            <div className={classNames(styles.imgContainer)}>
                <img className={styles.coverImg} src={coverImg} alt="" />
                <img className={styles.gridImg} src={gridImg} alt="" />
            </div>
        </div>
    );
};

export default PromoBlock;
