import { notify, POSITIONS, Status } from 'reapop';

export interface NotificationParams {
    title?: string;
    message?: string;
    status?: Status;
}

const notification = ({ title = '', message = '', status = 'info' }: NotificationParams) => {
    return notify(message, status, {
        title: title,
        dismissible: true,
        dismissAfter: 7000,
        position: POSITIONS.topRight,
        showDismissButton: true,
        allowHTML: true
    });
};

export const notifyInfo = ({ title = '', message = '' }: NotificationParams) =>
    notification({ title, message, status: 'info' });

export const notifySuccess = ({ title = '', message = '' }: NotificationParams) =>
    notification({ title, message, status: 'success' });

export const notifyError = ({ title = '', message = '' }: NotificationParams) =>
    notification({ title, message, status: 'error' });

export default notification;
