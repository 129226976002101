enum AppRoute {
    ROOT = '/',
    PROFILE = '/profile',
    PROFILE_API = '/profile/api',
    PROFILE_ORDERS = '/profile/orders',
    PROFILE_ALERTS = '/profile/alerts',
    REGISTRATION = '/registration',
    ABOUT = '/about',
    TARIFFS = '/tariffs',
    FAQ = '/faq',
    CONTACTS = '/contacts',
    PRIVACY = '/privacy',
    RESET_PASSWORD = '/reset-password',
    FAILED_PAYMENT = '/failed-payment',
    SUCCESSFUL_PAYMENT = '/successful-payment',

    /* тестовые */
    ORDERS_TEST = '/orders-test',
    FAQ_TEST = '/faq-test'
}

export default AppRoute;
