import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AppRoute from '../../constants/AppRoute';
import TabMenu from '../../components/tabMenu/TabMenu';
import { TabMenuItemSize, TabMenuItemTheme } from '../../components/tabMenu/tabMenuItem/TabMenuItem';
import styles from './AuthFormLayout.module.scss';

export enum AuthFormLayoutTheme {
    WHITE_BG = 'WHITE_BG',
    TRANSPARENT_BG = 'TRANSPARENT_BG'
}

export enum LoginTab {
    AUTHORIZATION = 'AUTHORIZATION',
    REGISTRATION = 'REGISTRATION'
}

export enum AutoFormLayoutSize {
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL'
}

interface Props extends PropsWithChildren {
    activeTab?: LoginTab;
    className?: string;
    theme?: AuthFormLayoutTheme;
    size?: TabMenuItemSize;
}

const AuthFormLayout: FC<Props> = ({
    children,
    activeTab,
    className,
    theme = AuthFormLayoutTheme.TRANSPARENT_BG,
    size = AutoFormLayoutSize.DEFAULT
}) => {
    const { t } = useTranslation('loginPage');

    return (
        <div
            className={classNames(styles.formContainer, className, {
                [styles.whiteContainer]: theme === AuthFormLayoutTheme.WHITE_BG
            })}
        >
            <div className={styles.loginVariants}>
                <TabMenu
                    items={[
                        {
                            link: AppRoute.PROFILE,
                            isActive: activeTab === LoginTab.AUTHORIZATION,
                            title: t('authorization')
                        },
                        {
                            link: AppRoute.REGISTRATION,
                            isActive: activeTab === LoginTab.REGISTRATION,
                            title: t('registration')
                        }
                    ]}
                    size={size === AutoFormLayoutSize.SMALL ? TabMenuItemSize.SMALL : undefined}
                    theme={theme === AuthFormLayoutTheme.WHITE_BG ? TabMenuItemTheme.LIGHT_BG : undefined}
                />
            </div>
            {children}
        </div>
    );
};

export default AuthFormLayout;
