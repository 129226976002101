type BooleanString = 'true' | 'false';

// см. .env.example
interface AppEnv {
    readonly REACT_APP_VERSION?: string;

    // region хосты
    readonly REACT_APP_API_HOST: string;
    // endregion

    // region флаги
    readonly REACT_APP_IS_GLOBAL_OBJECTS_SHARED?: BooleanString;
    readonly REACT_APP_IS_DEV_FEATURES_ENABLED?: BooleanString;
    // endregion
}

const {
    REACT_APP_VERSION,

    // region хосты
    REACT_APP_API_HOST,
    // endregion

    // region флаги
    REACT_APP_IS_GLOBAL_OBJECTS_SHARED,
    REACT_APP_IS_DEV_FEATURES_ENABLED
    // endregion
} = process.env as unknown as AppEnv;

export const VERSION = REACT_APP_VERSION;

// region хосты
export const API_HOST = REACT_APP_API_HOST;
// endregion

// region флаги
export const isGlobalObjectsShared = REACT_APP_IS_GLOBAL_OBJECTS_SHARED === 'true';
export const isDevFeaturesEnabled = REACT_APP_IS_DEV_FEATURES_ENABLED === 'true';
// endregion
