import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AppThunkDispatch } from '../../../../types/redux';
import DefaultInput from '../../../../components/defaultInput/DefaultInput';
import Button, { ButtonColor, ButtonType } from '../../../../components/button/Button';
import editUserPassword from '../../../../actions/user/editUserPassword';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../../../utils/logger';
import { User } from '../../../../types/api';
import { REQUIRED_FIELD } from '../../../../constants/validation';
import { notifyError, notifySuccess } from '../../../../utils/notification';
import styles from './ProfilePassword.module.scss';

interface ProfileFormValues {
    name: string;
    email: string;
    password: string;
    newPassword: string;
    confirmNewPassword: string;
}

interface Props {
    user: User;
    className?: string;
}

const logger = createLogger('ProfilePassword', ACTIONS_LOG_LEVEL);

const ProfilePassword: FC<Props> = ({ user, className }) => {
    const { t } = useTranslation('dashboard');
    const dispatch: AppThunkDispatch = useDispatch();

    const schema = Yup.object().shape({
        email: Yup.string().required(REQUIRED_FIELD).email(t('profile_tab.validation.invalid_email')),
        password: Yup.string().required(REQUIRED_FIELD).min(6, t('profile_tab.validation.short_password')),
        newPassword: Yup.string().required(REQUIRED_FIELD).min(6, t('profile_tab.validation.short_password')),
        confirmNewPassword: Yup.string()
            .required(REQUIRED_FIELD)
            .min(6, t('profile_tab.validation.short_password'))
            .oneOf([Yup.ref('newPassword')], t('profile_tab.validation.password_mismatch'))
    });

    const form = useForm<ProfileFormValues>({
        mode: 'onTouched',
        defaultValues: { email: user.email },
        resolver: yupResolver(schema)
    });

    const {
        register,
        setError,
        formState: { isSubmitting, isValid, errors },
        handleSubmit: onSubmit
    } = form;

    const handleSubmit = useCallback(
        (values: ProfileFormValues) => {
            dispatch(editUserPassword(values))
                .then(() => {
                    dispatch(notifySuccess({ message: t('profile_tab.password_change', { context: 'complete' }) }));
                })
                .catch((message) => {
                    dispatch(notifyError({ message: t('profile_tab.password_change', { context: 'error' }) }));
                    Object.entries(message).map(([key, value]) => {
                        setError(key as any, { message: value as string });
                    });
                });
        },
        [user.email, dispatch]
    );

    return (
        <form className={className} onSubmit={onSubmit(handleSubmit)}>
            <DefaultInput
                className={styles.input}
                placeholder="example@gmail.com"
                inputStyle="gray"
                aria-invalid={errors.email ? 'true' : 'false'}
                disabled
                {...register('email')}
            />
            {errors.email && <p className={styles.error}>{errors.email.message}</p>}

            <DefaultInput
                type="password"
                className={styles.input}
                placeholder={t('profile_tab.current_password')}
                inputStyle="black"
                aria-invalid={errors.password ? 'true' : 'false'}
                {...register('password')}
            />
            {errors.password && <p className={styles.error}>{errors.password.message}</p>}

            <DefaultInput
                type="password"
                className={styles.input}
                placeholder={t('profile_tab.new_password')}
                inputStyle="black"
                aria-invalid={errors.newPassword ? 'true' : 'false'}
                {...register('newPassword')}
            />
            {errors.newPassword && <p className={styles.error}>{errors.newPassword.message}</p>}

            <DefaultInput
                type="password"
                className={styles.input}
                placeholder={t('profile_tab.new_password_repeat')}
                inputStyle="black"
                aria-invalid={errors.confirmNewPassword ? 'true' : 'false'}
                {...register('confirmNewPassword')}
            />
            {errors.confirmNewPassword && <p className={styles.error}>{errors.confirmNewPassword.message}</p>}

            <div className={styles.buttonBlock}>
                <Button
                    className={styles.button}
                    type={ButtonType.SUBMIT}
                    color={ButtonColor.TRANSPARENT}
                    disabled={isSubmitting || !isValid}
                >
                    {t('profile_tab.save')}
                </Button>
            </div>
        </form>
    );
};

export default ProfilePassword;
