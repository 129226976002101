import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import selectUser from '../../selectors/selectUser';
import ProfileMenu from '../../components/profileMenu/ProfileMenu';
import ProfilePassword from './components/profilePassword/ProfilePassword';
import ProfileInfo from './components/profileInfo/ProfileInfo';
import ProfileSubscription from './components/profileSubscription/ProfileSubscription';
import ProfileBlock from './components/profileBlock/ProfileBlock';
import styles from './ProfilePage.module.scss';

const ProfilePage: FC = () => {
    const { t } = useTranslation('dashboard');
    const user = useSelector(selectUser);

    if (!user) {
        return <p>{t('profile_tab.no_data')}</p>;
    }

    return (
        <>
            <ProfileMenu />
            <div className={styles.profileBlocks}>
                <ProfileBlock title={t('profile_tab.account_data')} className={styles.highProfileBlock}>
                    <ProfilePassword className={styles.form} user={user} />
                </ProfileBlock>
                <ProfileBlock title={t('profile_tab.personal_data')} className={styles.highProfileBlock}>
                    <ProfileInfo className={styles.form} user={user} />
                </ProfileBlock>
                <ProfileBlock>
                    <ProfileSubscription className={styles.form} />
                </ProfileBlock>
            </div>
        </>
    );
};

export default ProfilePage;
