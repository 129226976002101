import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import login from '../../../actions/user/login';
import { AppThunkDispatch } from '../../../types/redux';
import AppRoute from '../../../constants/AppRoute';
import { REQUIRED_FIELD } from '../../../constants/validation';
import Button, { ButtonColor, ButtonFormatSize, ButtonType } from '../../../components/button/Button';
import { AuthFormLayoutTheme } from '../../../layouts/authFormLayout/AuthFormLayout';
import inputStyles from '../../../components/loginInput/LoginInput.module.scss';
import styles from './LoginForm.module.scss';
import classNames from 'classnames';

export enum AuthFormSize {
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL'
}

interface LoginFormValues {
    email: string;
    password: string;
}

interface Props {
    theme?: AuthFormLayoutTheme;
    size?: AuthFormSize;
}

const LoginForm: FC<Props> = ({ theme, size }) => {
    const { t } = useTranslation('loginPage');
    const dispatch: AppThunkDispatch = useDispatch();
    const navigate = useNavigate();

    const [isFormError, setIsFormError] = useState<boolean>();

    const schema = Yup.object().shape({
        email: Yup.string().required(REQUIRED_FIELD).email(t('invalid_email')),
        password: Yup.string().required(REQUIRED_FIELD).min(6, t('short_password'))
    });

    const form = useForm<LoginFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    const {
        register,
        setError,
        formState: { isSubmitting, isValid, errors },
        handleSubmit: onSubmit
    } = form;

    const handleSubmit = useCallback(
        (values: any) => {
            setIsFormError(false);

            dispatch(login(values.email, values.password))
                .then(() => navigate(AppRoute.PROFILE))
                .catch((message) => {
                    setIsFormError(true);
                });
        },
        [dispatch]
    );

    return (
        <>
            <form className={styles.form} onSubmit={onSubmit(handleSubmit)}>
                <div className={styles.group}>
                    <input
                        className={inputStyles.input}
                        placeholder={t('email')}
                        aria-invalid={errors.email ? 'true' : 'false'}
                        required
                        {...register('email')}
                    />
                    {errors.email && (
                        <p className={classNames(inputStyles.message, inputStyles.error)}>{errors.email.message}</p>
                    )}
                </div>

                <div className={styles.group}>
                    <input
                        type="password"
                        className={inputStyles.input}
                        placeholder={t('password')}
                        aria-invalid={errors.password ? 'true' : 'false'}
                        required
                        {...register('password')}
                    />
                    {errors.password && (
                        <p className={classNames(inputStyles.message, inputStyles.error)}>{errors.password.message}</p>
                    )}
                </div>

                <Button
                    type={ButtonType.SUBMIT}
                    className={styles.button}
                    color={theme === AuthFormLayoutTheme.WHITE_BG ? ButtonColor.TRANSPARENT : ButtonColor.GREEN}
                    disabled={isSubmitting || !isValid}
                    formatSize={size === AuthFormSize.SMALL ? ButtonFormatSize.REDUCED : undefined}
                >
                    {t('login')}
                </Button>

                {isFormError ? <p className={inputStyles.error}>{t('invalid_login')}</p> : null}
            </form>

            {/*TODO: реализовать*/}
            {/*<ScrollLink to={AppRoute.RESET_PASSWORD} className={styles.forgot}>
                {t('forgot_password')}
            </ScrollLink>*/}
        </>
    );
};

export default LoginForm;
