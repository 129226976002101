import { AppThunkAction } from '../../types/redux';
import { request } from '../../utils/request';
import { Account } from '../../types/api';
import { setAccounts } from '../../redux/reducers/account';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';

const logger = createLogger('fetchUser', ACTIONS_LOG_LEVEL);

const getAccounts = (): AppThunkAction => async (dispatch) => {
    try {
        const accounts = await request<Account[]>({ url: '/accounts/me/list' });
        dispatch(setAccounts(accounts));
    } catch (error) {
        logger.error('fetchUser: catch error', error);
        dispatch(setAccounts(undefined));
    }
};

export default getAccounts;
