import React, { FC } from 'react';
import styles from './TarifsPage.module.css';

const TariffsPage: FC = () => {
    return (
        <div className={styles.container}>

        </div>
    );
};

export default TariffsPage;
