import { put } from '../../utils/request';
import { User } from '../../types/api';
import { setUser } from '../../redux/reducers/application';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';

const logger = createLogger('editUser', ACTIONS_LOG_LEVEL);

interface Param {
    password: string;
    newPassword: string;
    confirmNewPassword: string;
}

const editUserPassword = (data: Param) => async (dispatch) => {
    try {
        const user = await put<User>({
            url: `/users/password`,
            data
        });
        return dispatch(setUser(user));
    } catch (error) {
        logger.error('catch error', error);
        throw error;
    }
};

export default editUserPassword;
