import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import PlusIcon, { PlusIconSize, PlusIconType } from '../plusIcon/PlusIcon';
import useLockScroll from '../../hooks/useLockScroll/useLockScroll';
import styles from './Popup.module.scss';

export enum PopupSize {
    NARROW = 'NARROW',
    DEFAULT = 'DEFAULT'
}

export enum PopupPosition {
    BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export enum PopupStyle {
    LIGHT = 'LIGHT',
    DARK = 'DARK'
}

interface Props extends PropsWithChildren {
    isOpened: boolean;
    onTrigger(previousValue: boolean): void;
    size?: PopupSize;
    blocked?: boolean;
    position?: PopupPosition;
    style?: PopupStyle;
    cardClassName?: string;
}

const Popup: FC<Props> = ({
    children,
    isOpened,
    onTrigger,
    size,
    blocked = false,
    position,
    style = PopupStyle.DARK,
    cardClassName
}) => {
    const { pathname } = useLocation();
    const { setIsScrollLocked } = useLockScroll();

    const [previousPathname, setPreviousPathname] = useState(pathname);
    const [isRendered, setIsRendered] = useState(isOpened);
    const [isVisible, setIsVisible] = useState(false);

    const closePopup = useCallback(() => {
        onTrigger(false);
    }, [onTrigger]);

    useEffect(() => {
        if (blocked) {
            setIsScrollLocked(isOpened);
        }

        let timeoutId: NodeJS.Timeout;
        if (isOpened) {
            setIsRendered(true);
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setTimeout(() => setIsVisible(true), 20);
                });
            });
        } else {
            setIsVisible(false);
            timeoutId = setTimeout(() => setIsRendered(false), 300);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isOpened, setIsScrollLocked, blocked]);

    useEffect(() => {
        if (blocked && previousPathname !== pathname) {
            setPreviousPathname(pathname);
            closePopup();
        }
    }, [pathname, previousPathname, closePopup]);

    return isRendered ? (
        <div
            className={classNames(styles.popupWrapper, {
                [styles.wrapperOpened]: isVisible
            })}
        >
            <div
                className={classNames(styles.scroller, {
                    [styles.bottomRightScroller]: position === PopupPosition.BOTTOM_RIGHT
                })}
            >
                {blocked && (
                    <div
                        className={classNames(styles.closeWrapper, { [styles.activePointer]: isVisible })}
                        onClick={closePopup}
                    ></div>
                )}
                <div
                    className={classNames(
                        styles.popupCard,
                        {
                            [styles.narrowCard]: size === PopupSize.NARROW,
                            [styles.activePointer]: isVisible,
                            [styles.darkCard]: style === PopupStyle.DARK,
                            [styles.lightCard]: style === PopupStyle.LIGHT
                        },
                        cardClassName
                    )}
                >
                    {children}
                </div>
            </div>
            {blocked && (
                <div
                    onClick={closePopup}
                    className={classNames(styles.closeBtn, { [styles.activePointer]: isVisible })}
                >
                    <PlusIcon type={PlusIconType.CLOSE} size={PlusIconSize.LARGE} />
                </div>
            )}
        </div>
    ) : null;
};

export default Popup;
