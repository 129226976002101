import { put } from '../../utils/request';
import { User } from '../../types/api';
import { setUser } from '../../redux/reducers/application';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';

const logger = createLogger('editUser', ACTIONS_LOG_LEVEL);

interface Param {
    email: string;
    fullName?: string;
    telegram?: string;
    password?: string;
    confirm?: string;
}

const editUserInfo =
    ({ email, fullName, telegram, password, confirm }: Param) =>
    async (dispatch) => {
        try {
            const user = await put<User>({
                url: `/users/info`,
                data: {
                    email,
                    fullName,
                    telegram,
                    password,
                    confirm
                }
            });
            return dispatch(setUser(user));
        } catch (error) {
            logger.error('catch error', error);
            throw error;
        }
    };

export default editUserInfo;
