import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileInstructionRow from '../profileInstructionRow/ProfileInstructionRow';
import { Account, Exchange } from '../../../../types/api';
import styles from './ProfileInstruction.module.scss';

interface Props extends PropsWithChildren {
    account?: Account;
}

const ProfileInstruction: FC<Props> = ({ account }) => {
    const { t } = useTranslation('dashboard');

    const renderStopLimitOrder = () => {
        if (!account) {
            return;
        }
        return (
            <>
                Стоп-лимитная заявка:
                <br />
                {'{'}
                <br />
                "account": "{account.id}",
                <br />
                "ticker": "{'{{ticker}}'}",
                <br />
                "direction": "{'{{strategy.order.action}}'}",
                <br />
                "type": "stop_limit",
                <br />
                "contracts": "{'{{strategy.order.contracts}}'}",
                <br />
                "price": "{'{{strategy.order.price}}'}"
                <br />
                "timeInForce": "oneday | immediateorcancel | fillorkill | goodtillcancelled",
                <br />
                "icebergFixed": "",
                <br />
                "icebergVariance": "",
                <br />
                "condition": "More | Less | MoreOrEqual | LessOrEqual",
                <br />
                "stopEndUnixTime": "",
                <br />
                "triggerPrice": "",
                <br />
                "activate": "true | false"
                <br />
                {'}'}
                <br />
                <br />
            </>
        );
    };

    const renderStopOrder = () => {
        if (!account) {
            return;
        }
        return (
            <>
                Стоп заявка:
                <br />
                {'{'}
                <br />
                "account": "{account.id}",
                <br />
                "ticker": "{'{{ticker}}'}",
                <br />
                "direction": "{'{{strategy.order.action}}'}",
                <br />
                "type": "stop",
                <br />
                "contracts": "{'{{strategy.order.contracts}}'}",
                <br />
                "icebergFixed": "",
                <br />
                "icebergVariance": "",
                <br />
                "condition": "More | Less | MoreOrEqual | LessOrEqual",
                <br />
                "stopEndUnixTime": "",
                <br />
                "triggerPrice": "",
                <br />
                "activate": "true | false"
                <br />
                {'}'}
                <br />
                <br />
            </>
        );
    };

    if (account) {
        return (
            <>
                <div className={styles.section} id="instruction">
                    <div className={styles.text}>{t('api_tab.text', { context: 'info_1' })}</div>

                    <ProfileInstructionRow title={t('api_tab.url_webhook')}>
                        <span className={styles.blue}>https://tv-signals.com/api/v1/orders</span>
                    </ProfileInstructionRow>

                    {account.exchange === Exchange.ALOR ? (
                        <ProfileInstructionRow title={t('api_tab.documentation')}>
                            <span className={styles.blue}>
                                <a href="https://alor.dev/docs" target="_blank">
                                    https://alor.dev/docs
                                </a>
                            </span>
                        </ProfileInstructionRow>
                    ) : null}

                    <ProfileInstructionRow
                        title={t('api_tab.message')}
                        // TODO продумать в будущем
                        // extra={
                        //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                        //         {t('api_tab.filling_example')}
                        //     </StyledLink>
                        // }
                    >
                        {/* market */}
                        Рыночная заявка:
                        <br />
                        {'{'}
                        <br />
                        "account": "{account.id}",
                        <br />
                        "ticker": "{'{{ticker}}'}",
                        <br />
                        "direction": "{'{{strategy.order.action}}'}",
                        <br />
                        "type": "market",
                        <br />
                        {account.exchange === Exchange.ALOR ? (
                            <>
                                "timeInForce": "oneday | immediateorcancel | fillorkill | goodtillcancelled",
                                <br />
                            </>
                        ) : null}
                        "contracts": "{'{{strategy.order.contracts}}'}"
                        <br />
                        {'}'}
                        <br />
                        <br />
                        {/* limit */}
                        Лимитная заявка:
                        <br />
                        {'{'}
                        <br />
                        "account": "{account.id}",
                        <br />
                        "ticker": "{'{{ticker}}'}",
                        <br />
                        "direction": "{'{{strategy.order.action}}'}",
                        <br />
                        "type": "limit",
                        <br />
                        "contracts": "{'{{strategy.order.contracts}}'}",
                        <br />
                        {account.exchange === Exchange.ALOR ? (
                            <>
                                "icebergFixed": "",
                                <br />
                                "icebergVariance": "",
                                <br />
                            </>
                        ) : null}
                        "price": "{'{{strategy.order.price}}'}"
                        <br />
                        {'}'}
                        <br />
                        <br />
                        {/* stop-limit / stop */}
                        {account.exchange === Exchange.ALOR ? renderStopLimitOrder() : null}
                        {account.exchange === Exchange.ALOR ? renderStopOrder() : null}
                        {t('api_tab.where')}
                        <br />
                        <span className={styles.blue}>ticker</span>
                        {t('api_tab.info', { context: 'ticker' })}
                        <br />
                        <span className={styles.blue}>direction</span>
                        {t('api_tab.info', { context: 'direction' })}
                        <br />
                        <span className={styles.blue}>type</span>
                        {t('api_tab.info', { context: 'type' })}
                        <br />
                        <span className={styles.blue}>contracts</span>
                        {t('api_tab.info', { context: 'contracts' })}
                        <br />
                        <span className={styles.blue}>price</span>
                        {t('api_tab.info', { context: 'price' })}
                        {account.exchange === Exchange.ALOR ? (
                            <>
                                <br />
                                <span className={styles.blue}>icebergFixed</span>
                                {t('api_tab.info', { context: 'icebergFixed' })}
                                <br />
                                <span className={styles.blue}>icebergVariance</span>
                                {t('api_tab.info', { context: 'icebergVariance' })}
                                <br />
                                <span className={styles.blue}>condition</span>
                                {t('api_tab.info', { context: 'condition' })}
                                <br />
                                <span className={styles.blue}>stopEndUnixTime</span>
                                {t('api_tab.info', { context: 'stopEndUnixTime' })}
                                <br />
                                <span className={styles.blue}>triggerPrice</span>
                                {t('api_tab.info', { context: 'triggerPrice' })}
                                <br />
                                <span className={styles.blue}>activate</span>
                                {t('api_tab.info', { context: 'activate' })}
                            </>
                        ) : null}
                    </ProfileInstructionRow>
                </div>

                <div className={styles.section}>
                    <div className={styles.text}>{t('api_tab.text', { context: 'info_2' })}</div>

                    <ProfileInstructionRow
                        title={t('api_tab.url_webhook')}
                        // TODO продумать в будущем
                        // extra={
                        //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                        //         {t('api_tab.filling_example')}
                        //     </StyledLink>
                        // }
                    >
                        <span className={styles.blue}>
                            https://tv-signals.com/api/v1/alerts?account={account.id}&chat_id=telegram_chat_id
                        </span>
                        <br />
                        {t('api_tab.where')}
                        <br />
                        <span className={styles.blue}>telegram_chat_id</span>
                        {t('api_tab.info_telegram_chat_id', { context: 'telegram_chat_id' })}
                    </ProfileInstructionRow>

                    <ProfileInstructionRow
                        title={t('api_tab.message')}
                        // TODO продумать в будущем
                        // extra={
                        //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                        //         {t('api_tab.filling_example')}
                        //     </StyledLink>
                        // }
                    >
                        {t('api_tab.info', { context: 'alert' })}
                    </ProfileInstructionRow>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.section} id="instruction">
                <div className={styles.text}>{t('api_tab.text', { context: 'info_1' })}</div>

                <ProfileInstructionRow title={t('api_tab.url_webhook')}>
                    <span className={styles.blue}>https://tv-signals.com/api/v1/orders</span>
                </ProfileInstructionRow>

                <ProfileInstructionRow
                    title={t('api_tab.message')}
                    // TODO продумать в будущем
                    // extra={
                    //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                    //         {t('api_tab.filling_example')}
                    //     </StyledLink>
                    // }
                >
                    {'{'}
                    <br />
                    "account": "uuid",
                    <br />
                    "ticker": "{'{{ticker}}'}",
                    <br />
                    "direction": "{'{{strategy.order.action}}'}",
                    <br />
                    "type": "market or limit",
                    <br />
                    "contracts": "{'{{strategy.order.contracts}}'}",
                    <br />
                    "price": "{'{{strategy.order.price}}'}"<br />
                    {'}'}
                    <br />
                    <br />
                    {t('api_tab.where')}
                    <br />
                    <span className={styles.blue}>account</span>
                    {t('api_tab.info', { context: 'account' })}
                    <br />
                    <span className={styles.blue}>ticker</span>
                    {t('api_tab.info', { context: 'ticker' })}
                    <br />
                    <span className={styles.blue}>direction</span>
                    {t('api_tab.info', { context: 'direction' })}
                    <br />
                    <span className={styles.blue}>type</span>
                    {t('api_tab.info', { context: 'type' })}
                    <br />
                    <span className={styles.blue}>contracts</span>
                    {t('api_tab.info', { context: 'contracts' })}
                    <br />
                    <span className={styles.blue}>price</span>
                    {t('api_tab.info', { context: 'price' })}
                </ProfileInstructionRow>
            </div>

            <div className={styles.section}>
                <div className={styles.text}>{t('api_tab.text', { context: 'info_2' })}</div>

                <ProfileInstructionRow
                    title={t('api_tab.url_webhook')}
                    // TODO продумать в будущем
                    // extra={
                    //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                    //         {t('api_tab.filling_example')}
                    //     </StyledLink>
                    // }
                >
                    <span className={styles.blue}>
                        https://tv-signals.com/api/v1/alerts?account=account_id&chat_id=telegram_chat_id
                    </span>
                    <br />
                    {t('api_tab.where')}
                    <br />
                    <span className={styles.blue}>account_id</span>
                    {t('api_tab.info', { context: 'account' })}
                    <br />
                    <span className={styles.blue}>telegram_chat_id</span>
                    {t('api_tab.info_telegram_chat_id', { context: 'telegram_chat_id' })}
                </ProfileInstructionRow>

                <ProfileInstructionRow
                    title={t('api_tab.message')}
                    // TODO продумать в будущем
                    // extra={
                    //     <StyledLink className={styles.grayLink} to="/web/src/pages">
                    //         {t('api_tab.filling_example')}
                    //     </StyledLink>
                    // }
                >
                    {t('api_tab.info', { context: 'alert' })}
                </ProfileInstructionRow>
            </div>
        </>
    );
};

export default ProfileInstruction;
