export type Pageable<T> = {
    content: T[];
    totalElements: number;
    totalPages: number;
    pageable: {
        pageNumber: number;
        pageSize: number;
    };
    number: number;
    size: number;
};

export enum ApiCode {
    OK = 200,
    NOT_AUTHORIZED = 401
}

export interface Login {
    accessToken: string;
    tokenType: string;
    user: User;
}

export interface User {
    id: string;
    email: string;
    fullName?: string;
    telegram?: string;
}

export interface Account {
    id: string;
    exchange?: string;
    apiKey?: string;
    secretKey?: string;
    telegramChatId?: string;
    isDemo?: boolean;
    isDeleted?: boolean;
    createdDate?: number;
}

export interface Instrument {
    id: string;
    ticker: string;
    title: string;
    code: string;
    exchange: Exchange;
}

export interface Order {
    id: string;
    status: OrderStatus;
    type: OrderType;
    direction: Direction;
    instrument: Instrument;
    open: number;
    quantity: number;
    createdDate: number;
}

export interface Alert {
    id: string;
    status: OrderStatus;
    message?: string;
    json?: string;
    createdDate: number;
}

export interface Subscription {
    id: string;
    type: SubscriptionType;
    amount: number;
    expiration: number;
    createdDate: number;
}

export enum SubscriptionType {
    TRIAL = 'TRIAL',
    PRO = 'PRO'
}

export enum Exchange {
    TINKOFF = 'TINKOFF',
    ALOR = 'ALOR',
    BINANCE_SPOT = 'BINANCE_SPOT',
    BINANCE_FUTURES = 'BINANCE_FUTURES'
}

export enum Direction {
    BUY = 'BUY',
    SELL = 'SELL'
}

export enum OrderStatus {
    NEW = 'NEW',
    CREATED = 'CREATED',
    REJECTED = 'REJECTED'
}

export enum OrderType {
    MARKET = 'MARKET',
    LIMIT = 'LIMIT'
}
