import React, { FunctionComponent } from 'react';
import LoginForm from './loginForm/LoginForm';
import LoginRegLayout from '../../layouts/loginRegLayout/LoginRegLayout';
import { AuthFormLayoutTheme, LoginTab } from '../../layouts/authFormLayout/AuthFormLayout';

interface Props {}

const LoginPage: FunctionComponent<Props> = () => {
    return (
        <LoginRegLayout activeTab={LoginTab.AUTHORIZATION} theme={AuthFormLayoutTheme.WHITE_BG}>
            <LoginForm theme={AuthFormLayoutTheme.WHITE_BG} />
        </LoginRegLayout>
    );
};

export default LoginPage;
