import { AppThunkAction } from '../../types/redux';
import { request } from '../../utils/request';
import { Order, Pageable } from '../../types/api';
import { setTrades } from '../../redux/reducers/order';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';
import { SIZE_ELEMENTS, START_PAGE } from '../../constants/page';

const logger = createLogger('getOrders', ACTIONS_LOG_LEVEL);

export interface OrdersFilterParam {
    page?: number;
    size?: number;
}

const getOrders =
    ({ page = START_PAGE, size = SIZE_ELEMENTS }: OrdersFilterParam = {}): AppThunkAction =>
    async (dispatch) => {
        try {
            const orders = await request<Pageable<Order>>({
                url: '/orders/me/list',
                params: {
                    page,
                    size
                }
            });
            dispatch(setTrades(orders));
        } catch (error) {
            logger.error('catch error', error);
            dispatch(setTrades(undefined));
        }
    };

export default getOrders;
