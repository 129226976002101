import React, { FC, PropsWithChildren } from 'react';
import styles from './PrivacyParagraph.module.scss';

interface Props extends PropsWithChildren {
    title: string;
}

const PrivacyParagraph: FC<Props> = ({ title, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div>{children}</div>
        </div>
    );
};

export default PrivacyParagraph;
