import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './BurgerIcon.module.css';

export enum BurgerIconType {
    CLOSE = 'close',
    BURGER = 'burger'
}

interface Props {
    type?: BurgerIconType;
    className?: string;
    onClick?(): void;
}

const BurgerIcon: FC<Props> = ({ type = BurgerIconType.BURGER, onClick, className }) => {
    return (
        <div
            className={classNames(styles.container, className, {
                [styles.close]: type === BurgerIconType.CLOSE,
                [styles.burger]: type === BurgerIconType.BURGER
            })}
            onClick={onClick}
        >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
        </div>
    );
};

export default BurgerIcon;
