import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './PlusIcon.module.scss';

export enum PlusIconType {
    PLUS = 'PLUS',
    CLOSE = 'CLOSE',
    MINUS = 'MINUS'
}

export enum PlusIconColor {
    GRAY = 'GRAY',
    BLACK = 'BLACK',
    WHITE = 'WHITE',
    BLUE = 'BLUE'
}

export enum PlusIconSize {
    LARGE = 'LARGE',
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL'
}

interface Props {
    type?: PlusIconType;
    size?: PlusIconSize;
    color?: PlusIconColor;
}

const PlusIcon: FC<Props> = ({ type, size, color }) => {
    return (
        <div
            className={classNames(
                styles.container,
                {
                    [styles.closeIcon]: type === PlusIconType.CLOSE,
                    [styles.minusIcon]: type === PlusIconType.MINUS
                },
                {
                    [styles.grayIcon]: color === PlusIconColor.GRAY,
                    [styles.blackIcon]: color === PlusIconColor.BLACK,
                    [styles.blueIcon]: color === PlusIconColor.BLUE
                },
                {
                    [styles.smallIcon]: size === PlusIconSize.SMALL,
                    [styles.largeIcon]: size === PlusIconSize.LARGE
                }
            )}
        ></div>
    );
};

export default PlusIcon;
