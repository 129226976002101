import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import HomePageTitle from '../homePageTitle/HomePageTitle';
import getStringKey from '../../../../utils/getStringKey';
import CapabilityItem from './capabilityItem/CapabilityItem';
import styles from './CapabilitiesBlock.module.scss';

interface Props {
    className?: string;
}

const CapabilitiesBlock: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');

    const capabilities: string[] = [
        t('capabilities_block.capability', { context: 1 }),
        t('capabilities_block.capability', { context: 2 }),
        t('capabilities_block.capability', { context: 3 }),
        t('capabilities_block.capability', { context: 4 }),
        t('capabilities_block.capability', { context: 5 })
    ];

    return (
        <div className={className}>
            <HomePageTitle superTitle={t('capabilities_block.super_title')}>
                {t('capabilities_block.title')}
            </HomePageTitle>
            <div className={styles.items}>
                {capabilities.map((capability, index) => (
                    <CapabilityItem className={styles.item} key={getStringKey(index, 'capability')}>
                        {capability}
                    </CapabilityItem>
                ))}
            </div>
        </div>
    );
};

export default CapabilitiesBlock;
