import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import TabMenuItem, { TabItem, TabMenuItemSize, TabMenuItemTheme } from './tabMenuItem/TabMenuItem';
import getStringKey from '../../utils/getStringKey';
import styles from './TabMenu.module.scss';

interface Props {
    items: TabItem[];
    autoActive?: boolean;
    className?: string;
    size?: TabMenuItemSize;
    theme?: TabMenuItemTheme;
}

const TabMenu: FC<Props> = ({ items, autoActive, className, size, theme }) => {
    const { pathname } = useLocation();

    return (
        <div className={classNames(styles.container, className)}>
            {items.map((item, index) => {
                let isActive = item.isActive;
                if (autoActive && isActive === undefined) {
                    isActive = pathname === item.link;
                }

                return (
                    <TabMenuItem
                        link={item.link}
                        iconLink={item.iconLink}
                        isActive={isActive}
                        title={item.title}
                        className={styles.item}
                        size={size}
                        theme={theme}
                        key={getStringKey(index, 'tabMenuItem')}
                    />
                );
            })}
        </div>
    );
};

export default TabMenu;
