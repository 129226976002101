import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import selectIsAuthorized from '../../selectors/selectIsAuthorized';
import LoginPage from '../../pages/loginPage/LoginPage';

interface Props {
    isRequiredAuth?: boolean;
}

const AuthLayout: FunctionComponent<Props> = ({ isRequiredAuth }) => {
    const isAuthorized = useSelector(selectIsAuthorized);

    if (isRequiredAuth && !isAuthorized) {
        return <LoginPage />;
    }

    return <Outlet />;
};

export default AuthLayout;
