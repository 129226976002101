import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import advantageIcon1 from '../../../../images/advantage1.svg';
import advantageIcon2 from '../../../../images/advantage2.svg';
import advantageIcon3 from '../../../../images/advantage3.svg';
import advantageIcon4 from '../../../../images/advantage4.svg';
import advantageIcon5 from '../../../../images/advantage5.svg';
import AdvantagesItem, { Advantage } from './advantageItem/AdvantageItem';
import HomePageButton from '../homePageButton/HomePageButton';
import classNames from 'classnames';
import HomePageTitle from '../homePageTitle/HomePageTitle';
import getStringKey from '../../../../utils/getStringKey';
import styles from './AdvantagesBlock.module.scss';

interface Props {
    className?: string;
}

const AdvantagesBlock: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');

    const advantages: Advantage[] = [
        {
            content: t('advantages_block.item', { context: 1 }),
            iconLink: advantageIcon1
        },
        {
            content: t('advantages_block.item', { context: 2 }),
            iconLink: advantageIcon2
        },
        {
            content: t('advantages_block.item', { context: 3 }),
            iconLink: advantageIcon3
        },
        {
            content: t('advantages_block.item', { context: 4 }),
            iconLink: advantageIcon4
        },
        {
            content: (
                <>
                    {t('advantages_block.item', { context: '5_1' })}
                    <span className={styles.greenText}>{t('advantages_block.item', { context: '5_green' })}</span>
                    {t('advantages_block.item', { context: '5_2' })}
                </>
            ),
            iconLink: advantageIcon5
        }
    ];

    return (
        <div className={classNames(styles.container, className)} id="advantagesBlock">
            <HomePageTitle superTitle={t('advantages_block.super_title')}>{t('advantages_block.title')}</HomePageTitle>
            <div className={styles.items}>
                {advantages.map((advantage, index) => (
                    <AdvantagesItem className={styles.item} data={advantage} key={getStringKey(index, 'advantage')} />
                ))}
            </div>
            <p className={styles.buttonString}>
                <HomePageButton />
            </p>
        </div>
    );
};

export default AdvantagesBlock;
