import { castImmutable } from 'immer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Alert, Pageable } from '../../types/api';
import actionCreator from '../utils/actionCreator';
import immerCase from '../utils/immerCase';

export interface AlertState {
    alerts?: Pageable<Alert>;
}

const initialState = castImmutable<AlertState>({});

export const setAlerts = actionCreator<Pageable<Alert> | undefined>('setAlerts');

// @ts-ignores
export default reducerWithInitialState<AlertState>(initialState).withHandling(
    immerCase(setAlerts, (draft, payload) => {
        draft.alerts = payload;
    })
);
