import React, { FC } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppRouter from './layouts/AppRouter';
import Notifications from './layouts/notifications/Notifications';
import LoadingLayout from './layouts/loadingLayout/LoadingLayout';

const App: FC = () => (
    <Provider store={store}>
        <React.Suspense fallback={<LoadingLayout />}>
            <AppRouter />
        </React.Suspense>

        <Notifications />
    </Provider>
);

export default App;
