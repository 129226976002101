import React, { FC, PropsWithChildren } from 'react';
import { HashLinkProps } from 'react-router-hash-link';
import classNames from 'classnames';
import ScrollLink from '../../../../components/scrollLink/ScrollLink';
import styles from './MenuItem.module.scss';

interface Props extends PropsWithChildren<HashLinkProps> {
    href?: any;
    isActive?: boolean;
}

const MenuItem: FC<Props> = ({ children, isActive, ...otherProps }) => {
    return (
        <ScrollLink className={classNames(styles.item, { [styles.isActive]: isActive })} {...otherProps}>
            {children}
        </ScrollLink>
    );
};

export default MenuItem;
