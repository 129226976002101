import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AppRoute from '../../../../constants/AppRoute';
import HashRoute from '../../../../constants/HashRoute';
import { useSelector } from 'react-redux';
import selectIsAuthorized from '../../../../selectors/selectIsAuthorized';
import Button from '../../../../components/button/Button';
import InnerLink from '../../../../components/innerLink/InnerLink';

interface Props {
    className?: string;
}

const HomePageButton: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');
    const isAuthorized = useSelector(selectIsAuthorized);

    if (isAuthorized)
        return (
            <InnerLink className={className} to={AppRoute.PROFILE}>
                <Button className={className}>{t('dashboard_button')}</Button>
            </InnerLink>
        );

    return (
        <InnerLink to={HashRoute.TARIFFS}>
            <Button className={className}>{t('connect_button')}</Button>
        </InnerLink>
    );
};

export default HomePageButton;
