import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColor, ButtonFormatSize } from '../../../../../components/button/Button';
import AppRoute from '../../../../../constants/AppRoute';
import AuthFormLayout, { LoginTab } from '../../../../authFormLayout/AuthFormLayout';
import LoginForm from '../../../../../pages/loginPage/loginForm/LoginForm';
import Popup from '../../../../../components/popup/Popup';
import styles from '../Header.module.scss';

const LoginButtonPopup: FC = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [isPopupShown, setIsPopupOpened] = useState(false);

    const handlePopupOpen = () => {
        // TODO: продумать логику
        //  сейчас модалку можно вызвать в том числе находясь на странице авторизации
        // setIsPopupOpened(true);
        navigate(AppRoute.PROFILE);
    };

    return (
        <>
            <Button
                formatSize={ButtonFormatSize.REDUCED}
                color={ButtonColor.BLUE}
                onClick={handlePopupOpen}
                className={styles.button}
            >
                {t('dashboard_button')}
            </Button>
            <Popup isOpened={isPopupShown} onTrigger={setIsPopupOpened} blocked>
                <AuthFormLayout activeTab={LoginTab.AUTHORIZATION} className={styles.form}>
                    <LoginForm />
                </AuthFormLayout>
            </Popup>
        </>
    );
};

export default LoginButtonPopup;
