import { format } from 'date-fns';

export const fullDateMaskWithHours = 'yyyy-MM-dd HH:mm';
export const fullDateMask = 'yyyy-MM-dd';
export const directDateMask = 'dd.MM.yyyy';
export const directDateMaskEn = 'MMMM d, yyyy';

const formatDate = (date?: Date | number | string, mask: string = fullDateMaskWithHours) => {
    if (!date) {
        return;
    }
    return format(new Date(date), mask);
};

export default formatDate;
