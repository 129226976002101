import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PromoBlock from '../../components/promoBlock/PromoBlock';
import AppRoute from '../../constants/AppRoute';
import Button, { ButtonFormatSize } from '../../components/button/Button';
import InnerLink from '../../components/innerLink/InnerLink';
import styles from './SuccessfulPaymentPage.module.scss';

const SuccessfulPaymentPage: FC = () => {
    const { t } = useTranslation('payment');

    return (
        <div className={styles.container}>
            <PromoBlock fixedBg>
                <h1 className={styles.title}>
                    {t('successful', { context: 'title' })}
                    <span className={styles.greenText}>{t('successful', { context: 'title_highlighted' })}</span>
                </h1>
                <p className={styles.text}>{t('successful', { context: 'text_1' })}</p>
                <p className={styles.text}>{t('successful', { context: 'text_2' })}</p>
                <div className={styles.linkContainer}>
                    <InnerLink to={AppRoute.ROOT}>
                        <Button formatSize={ButtonFormatSize.LARGE}>{t('back_to_home')}</Button>
                    </InnerLink>
                </div>
            </PromoBlock>
        </div>
    );
};

export default SuccessfulPaymentPage;
