import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import ProfileMenu from '../../components/profileMenu/ProfileMenu';
import { ReduxState } from '../../redux/rootReducer';
import formatDate from '../../utils/formatDate';
import { Alert, OrderStatus } from '../../types/api';
import { SIZE_ELEMENTS, START_PAGE } from '../../constants/page';
import { OrdersFilterParam } from '../../actions/order/getOrders';
import ForkedTable from '../../components/forkedTable/ForkedTable';
import { AppThunkDispatch } from '../../types/redux';
import getAlerts from '../../actions/alert/getAlerts';
import styles from './ProfileAlertsPage.module.css';

const ProfileAlertsPage: FC = () => {
    const { t } = useTranslation('dashboard');
    const dispatch: AppThunkDispatch = useDispatch();
    const [filter, setFilter] = useState<OrdersFilterParam>({
        page: START_PAGE,
        size: SIZE_ELEMENTS
    });
    const alertsPageable = useSelector((state: ReduxState) => state.alert.alerts);

    const ORDER_STATUS_TITLE: Record<OrderStatus, string> = {
        [OrderStatus.NEW]: t('table.content.order_status', { context: 'new' }),
        [OrderStatus.CREATED]: t('table.content.order_status', { context: 'created' }),
        [OrderStatus.REJECTED]: t('table.content.order_status', { context: 'rejected' })
    };

    const columns: ColumnsType<any> = [
        {
            title: t('table.header.date'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: 150,
            render(value: number) {
                return value ? formatDate(value) : '-';
            }
        },
        {
            title: t('table.header.status'),
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render(value: OrderStatus) {
                return ORDER_STATUS_TITLE[value];
            }
        },
        {
            title: t('table.header.message'),
            dataIndex: 'message',
            key: 'message',
            width: 750,
            render(value: string, record: Alert) {
                return record.message || record.json || '-';
            }
        }
    ];

    const handleSearch = useCallback(
        (filter?: OrdersFilterParam) => {
            return dispatch(getAlerts(filter));
        },
        [dispatch]
    );

    const handleChangeCurrentPage = useCallback(
        (page: number, size: number) => {
            const nextFilter = {
                ...filter,
                page,
                size
            };
            setFilter(nextFilter);
            return handleSearch(nextFilter);
        },
        [filter, handleSearch]
    );

    return (
        <div className={styles.container}>
            <ProfileMenu />

            <ForkedTable columns={columns} pageable={alertsPageable} onChange={handleChangeCurrentPage} indexColumn />
        </div>
    );
};

export default ProfileAlertsPage;
