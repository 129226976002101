import { castImmutable } from 'immer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Subscription } from '../../types/api';
import actionCreator from '../utils/actionCreator';
import immerCase from '../utils/immerCase';

export interface SubscriptionState {
    subscription?: Subscription;
}

const initialState = castImmutable<SubscriptionState>({});

export const setSubscription = actionCreator<Subscription | undefined>('setSubscription');

// @ts-ignores
export default reducerWithInitialState<SubscriptionState>(initialState).withHandling(
    immerCase(setSubscription, (draft, payload) => {
        draft.subscription = payload;
    })
);
