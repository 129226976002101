import React, { FC, useState } from 'react';
import classNames from 'classnames';
import AccordionItem, {
    AccordionHeaderPositions,
    AccordionItemSize,
    AccordionItemStyle
} from './accordionItem/AccordionItem';
import getStringKey from '../../utils/getStringKey';
import styles from './AccordionBlock.module.scss';

export interface AccordionItemData {
    title: string | JSX.Element;
    content: string | JSX.Element;
    headerExtra?: string | JSX.Element;
}

interface Props {
    data: AccordionItemData[];
    className?: string;
    size?: AccordionItemSize;
    style?: AccordionItemStyle;
    headerPositions?: AccordionHeaderPositions;
}

const AccordionBlock: FC<Props> = ({ data, className, size, style, headerPositions }) => {
    const [openedItem, setOpenedItem] = useState<string>();

    const handleToggleItem = (itemKey: string) => () => {
        if (itemKey === openedItem) {
            setOpenedItem('');
        } else {
            setOpenedItem(itemKey);
        }
    };

    return (
        <div
            className={classNames(styles.items, className, {
                [styles.small]: size === AccordionItemSize.SMALL,
                [styles.light]: style === AccordionItemStyle.LIGHT
            })}
        >
            {data.map(({ title, content, headerExtra }, index) => {
                const key = getStringKey(index, 'accordionItem');
                return (
                    <AccordionItem
                        title={title}
                        onToggle={handleToggleItem(key)}
                        key={key}
                        opened={openedItem === key}
                        size={size}
                        style={style}
                        headerPositions={headerPositions}
                        headerExtra={headerExtra}
                    >
                        {content}
                    </AccordionItem>
                );
            })}
        </div>
    );
};

export default AccordionBlock;
