import React, { FC, ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './DefaultInput.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    type?: string;
    placeholder?: string;
    inputStyle?: 'black' | 'gray';
}

const DefaultInput: FC<Props> = forwardRef(
    ({ className, type, placeholder, inputStyle, ...otherProps }, ref: ForwardedRef<HTMLInputElement>) => {
        return (
            <input
                ref={ref}
                type={type}
                className={classNames(
                    styles.input,
                    {
                        [styles.black]: inputStyle === 'black',
                        [styles.gray]: inputStyle === 'gray'
                    },
                    className
                )}
                placeholder={placeholder}
                {...otherProps}
            />
        );
    }
);

export default DefaultInput;
