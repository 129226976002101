import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Wrapper from '../../../../components/wrapper/Wrapper';
import logoImg from '../../../../images/logo.svg';
import HeaderMenu from '../headerMenu/HeaderMenu';
import AppRoute from '../../../../constants/AppRoute';
import MobileMenu from '../mobileMenu/MobileMenu';
import HashRoute from '../../../../constants/HashRoute';
import { HeaderRoute } from '../../../../types/app';
import selectIsAuthorized from '../../../../selectors/selectIsAuthorized';
import logout from '../../../../actions/user/logout';
import { AppThunkDispatch } from '../../../../types/redux';
import LoginButtonPopup from './loginButtonPopup/LoginButtonPopup';
import ScrollLink from '../../../../components/scrollLink/ScrollLink';
import InnerLink from '../../../../components/innerLink/InnerLink';
import LanguageSelector from '../../../../components/languageSelector/LanguageSelector';
import useIsMedia from '../../../../hooks/useIsMedia';
import profileIcon from './icons/profile.svg';
import logoutIcon from './icons/logout.svg';
import styles from './Header.module.scss';

const Header: FC = () => {
    const { t } = useTranslation('common');
    const dispatch: AppThunkDispatch = useDispatch();
    const isAuthorized = useSelector(selectIsAuthorized);
    const [isFloating, setIsFloating] = useState(false);
    const { isDesktop, isMobile } = useIsMedia();

    const routes: HeaderRoute[] = [
        {
            to: HashRoute.ADVANTAGES,
            title: t('header_menu.advantages')
        },
        {
            to: HashRoute.TARIFFS,
            title: t('header_menu.tariffs')
        },
        {
            to: HashRoute.FAQ,
            title: t('header_menu.faq')
        }
        /*TODO*/
        /*{
            href: AppRoute.CONTACTS,
            title: 'Контакты'
        }*/
    ];

    const handleLogout = () => dispatch(logout());

    const floatingStatusUpdate = () => {
        window.scrollY > 0 ? setIsFloating(true) : setIsFloating(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', floatingStatusUpdate);
        return () => {
            window.removeEventListener('scroll', floatingStatusUpdate);
        };
    });

    return (
        <div className={classNames(styles.container, { [styles.floating]: isFloating })}>
            <Wrapper className={styles.wrapper}>
                <div className={styles.content}>
                    <ScrollLink to={`${AppRoute.ROOT}#`}>
                        <img className={styles.logoImage} src={logoImg} alt="" />
                    </ScrollLink>
                    {isDesktop && (
                        <div className={styles.headerMenu}>
                            <HeaderMenu routes={routes} />
                        </div>
                    )}
                    <div className={styles.profileBlock}>
                        {isDesktop && <LanguageSelector className={styles.profileItem} />}
                        {isAuthorized ? (
                            <>
                                <InnerLink to={AppRoute.PROFILE} className={styles.profileItem}>
                                    <img src={profileIcon} alt={t('profile')} />
                                </InnerLink>
                                {/*TODO*/}
                                {/*<Link to="" className={styles.profileItem}>*/}
                                {/*    <img src={notificationIcon} alt={t('notifications')} />*/}
                                {/*</Link>*/}
                                {isDesktop && (
                                    <Link to="" className={styles.profileItem} onClick={handleLogout}>
                                        <img src={logoutIcon} alt={t('logout')} />
                                    </Link>
                                )}
                            </>
                        ) : (
                            <>{isDesktop && <LoginButtonPopup />}</>
                        )}
                        {isMobile && <MobileMenu className={styles.profileItem} routes={routes} />}
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Header;
