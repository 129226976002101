import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AccordionBlock, { AccordionItemData } from '../../../../components/accordionBlock/AccordionBlock';
import HomePageTitle from '../homePageTitle/HomePageTitle';
import styles from './FaqBlock.module.scss';

interface Props {
    className?: string;
}

const FaqBlock: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');

    const faqData: AccordionItemData[] = [
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_1.question')}</div>,
            content: <>{t('faq_block.item_1.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_2.question')}</div>,
            content: <>{t('faq_block.item_2.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_3.question')}</div>,
            content: <>{t('faq_block.item_3.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_4.question')}</div>,
            content: <>{t('faq_block.item_4.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_5.question')}</div>,
            content: <>{t('faq_block.item_5.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_6.question')}</div>,
            content: <>{t('faq_block.item_6.answer')}</>
        }
    ];

    return (
        <div className={className} id="faqBlock">
            <HomePageTitle className={styles.title}>FAQ</HomePageTitle>
            <AccordionBlock data={faqData} />
        </div>
    );
};

export default FaqBlock;
