import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import noDataIcon from './nodata.png';
import styles from './EmptyPlaceholder.module.scss';

interface Props {
    className?: string;
}

const EmptyPlaceholder: FC<Props> = ({ className }) => {
    const { t } = useTranslation('dashboard');

    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.icon} src={noDataIcon} alt="" />
            {t('table.content.no_data')}
        </div>
    );
};

export default EmptyPlaceholder;
