import { FC, PropsWithChildren, MouseEvent, useEffect, useRef, useState } from 'react';
import classNames from '../../../aliases/classNames';
import PlusIcon, { PlusIconColor, PlusIconSize, PlusIconType } from '../../plusIcon/PlusIcon';
import styles from './AccordionItem.module.scss';

export enum AccordionItemSize {
    SMALL = 'SMALL',
    DEFAULT = 'DEFAULT'
}

export enum AccordionItemStyle {
    LIGHT = 'LIGHT'
}

export enum AccordionHeaderPositions {
    REVERSE = 'REVERSE'
}

interface Props extends PropsWithChildren {
    title: string | JSX.Element;
    opened: boolean;
    onToggle(): void;
    className?: string;
    style?: AccordionItemStyle;
    size?: AccordionItemSize;
    headerPositions?: AccordionHeaderPositions;
    headerExtra?: string | JSX.Element;
}

const getIconType = (isOpened: boolean, size: AccordionItemSize) => {
    if (isOpened) {
        if (size === AccordionItemSize.SMALL) {
            return PlusIconType.MINUS;
        }
        return PlusIconType.CLOSE;
    }

    return PlusIconType.PLUS;
};

const AccordionItem: FC<Props> = ({
    children,
    title,
    opened,
    onToggle,
    className,
    style,
    size = AccordionItemSize.DEFAULT,
    headerPositions,
    headerExtra
}) => {
    const headerExtraRef = useRef<HTMLDivElement>(null);
    const contentWrapperRef = useRef<HTMLInputElement>(null);
    const contentBlockRef = useRef<HTMLInputElement>(null);

    const [currentIconType, setCurrentIconType] = useState(getIconType(opened, size));

    const faqToggle = (event: MouseEvent<HTMLDivElement>) => {
        if (
            headerExtraRef.current &&
            (event.target === headerExtraRef.current || headerExtraRef.current.contains(event.target as Node))
        ) {
            return;
        }

        contentWrapperRef.current?.style.setProperty('--content-height', `${contentBlockRef.current?.clientHeight}px`);
        onToggle();
    };

    useEffect(() => {
        setCurrentIconType(getIconType(opened, size));
    }, [opened, size]);

    return (
        <div
            className={classNames(
                styles.container,
                {
                    [styles.lightStyle]: style === AccordionItemStyle.LIGHT,
                    [styles.small]: size === AccordionItemSize.SMALL,
                    [styles.opened]: opened
                },
                className
            )}
        >
            <div
                className={classNames(styles.header, {
                    [styles.reverseHeader]: headerPositions === AccordionHeaderPositions.REVERSE
                })}
                onClick={faqToggle}
            >
                {headerExtra && (
                    <div className={styles.headerExtra} ref={headerExtraRef}>
                        {headerExtra}
                    </div>
                )}
                <div className={styles.title}>{title}</div>
                <div className={styles.plusIcon}>
                    <PlusIcon
                        type={currentIconType}
                        color={style === AccordionItemStyle.LIGHT ? PlusIconColor.BLUE : undefined}
                        size={size === AccordionItemSize.SMALL ? PlusIconSize.SMALL : undefined}
                    />
                </div>
            </div>
            <div className={styles.contentWrapper} ref={contentWrapperRef}>
                <div className={styles.content} ref={contentBlockRef}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;
