import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import AppRoute from '../../../../constants/AppRoute';
import MenuItem from '../menuItem/MenuItem';
import { HeaderRoute } from '../../../../types/app';
import LoginForm, { AuthFormSize } from '../../../../pages/loginPage/loginForm/LoginForm';
import AuthFormLayout, { LoginTab } from '../../../authFormLayout/AuthFormLayout';
import { Link, useLocation } from 'react-router-dom';
import StyledLink from '../../../../components/styledLink/StyledLink';
import getStringKey from '../../../../utils/getStringKey';
import { useDispatch, useSelector } from 'react-redux';
import BurgerIcon, { BurgerIconType } from '../../../../components/burgerIcon/BurgerIcon';
import { AppThunkDispatch } from '../../../../types/redux';
import logout from '../../../../actions/user/logout';
import logoutIcon from '../header/icons/logout.svg';
import selectIsAuthorized from '../../../../selectors/selectIsAuthorized';
import { CURRENT_EMAIL_SUPPORT } from '../../../../constants/companyData';
import { TabMenuItemSize } from '../../../../components/tabMenu/tabMenuItem/TabMenuItem';
import MailLink from '../../../../components/mailLink/MailLink';
import useLockScroll from '../../../../hooks/useLockScroll/useLockScroll';
import LanguageSelector from '../../../../components/languageSelector/LanguageSelector';
import styles from './MobileMenu.module.scss';

interface Props {
    routes: HeaderRoute[];
    className?: string;
}

const MobileMenu: FC<Props> = ({ routes, className }) => {
    const { t: commonT } = useTranslation('common');

    const dispatch: AppThunkDispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const isAuthorized = useSelector(selectIsAuthorized);

    const { setIsScrollLocked } = useLockScroll();

    const handleLogout = () => dispatch(logout());

    const { pathname } = useLocation();
    const [previousPathname, setPreviousPathname] = useState(pathname);

    const handleCloseMenu = () => {
        setIsOpen(false);
    };

    const handleMenuToggle = () => {
        setIsOpen((previousValue: boolean) => {
            return !previousValue;
        });
    };

    useEffect(() => {
        setIsScrollLocked(isOpen);
    }, [setIsScrollLocked, isOpen]);

    useEffect(() => {
        if (previousPathname !== pathname) {
            setPreviousPathname(pathname);
            handleCloseMenu();
        }
    }, [pathname, previousPathname]);

    return (
        <div className={classNames({ [styles.isOpen]: isOpen }, className)}>
            <BurgerIcon onClick={handleMenuToggle} />
            <div className={classNames(styles.pageFiller)}>
                <div className={styles.pageFillerBg} onClick={handleCloseMenu}></div>
                <div className={classNames(styles.floatingMenu)}>
                    <div className={styles.header}>
                        {isAuthorized && (
                            <Link to="" onClick={handleLogout}>
                                <img src={logoutIcon} alt={commonT('logout')} />
                            </Link>
                        )}
                        <LanguageSelector className={styles.languageSelector} />
                        <BurgerIcon
                            type={BurgerIconType.CLOSE}
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                            className={styles.closeButton}
                        />
                    </div>
                    <div className={styles.gradientHiderOpen}>&nbsp;</div>
                    <div className={styles.scrollWrapper}>
                        <div className={styles.scroller}>
                            <div className={styles.content}>
                                {routes.map(({ to, title }, index) => (
                                    <MenuItem
                                        key={getStringKey(index, 'routeMenuItem')}
                                        to={to}
                                        onClick={handleCloseMenu}
                                    >
                                        {title}
                                    </MenuItem>
                                ))}
                                {!isAuthorized && (
                                    <AuthFormLayout
                                        className={styles.form}
                                        activeTab={LoginTab.AUTHORIZATION}
                                        size={TabMenuItemSize.SMALL}
                                    >
                                        <LoginForm size={AuthFormSize.SMALL} />
                                    </AuthFormLayout>
                                )}
                            </div>

                            <div className={styles.footer}>
                                <MailLink mail={CURRENT_EMAIL_SUPPORT} />
                                <StyledLink to={AppRoute.PRIVACY}>{commonT('privacy_policy')}</StyledLink>
                            </div>
                        </div>
                    </div>
                    <div className={styles.gradientHiderClose}>&nbsp;</div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
