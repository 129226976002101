import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import ProfileTitle from '../profileTitle/ProfileTitle';
import styles from './ProfileBlock.module.scss';

interface Props extends PropsWithChildren {
    title?: string;
    className?: string;
}

const ProfileBlock: FC<Props> = ({ children, title, className }) => {
    return (
        <div className={classNames(styles.profileBlock, className)}>
            {title && <ProfileTitle>{title}</ProfileTitle>}
            <div className={styles.profileBlockContent}>{children}</div>
        </div>
    );
};

export default ProfileBlock;
