import React, { FC } from 'react';
import styles from './ContactsPage.module.css';

const ContactsPage: FC = () => {
    return (
        <div className={styles.container}>

        </div>
    );
};

export default ContactsPage;
