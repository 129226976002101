import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PromoBlock from '../../components/promoBlock/PromoBlock';
import Button, { ButtonColor, ButtonFormatSize } from '../../components/button/Button';
import styles from './FailedPaymentPage.module.scss';

const FailedPaymentPage: FC = () => {
    const { t } = useTranslation('payment');

    return (
        <div className={styles.container}>
            <PromoBlock fixedBg>
                <h1 className={styles.title}>
                    <span className={styles.redText}>{t('failed', { context: 'title_highlighted' })}</span>
                    {t('failed', { context: 'title' })}
                </h1>
                <p className={styles.text}>{t('failed', { context: 'text_1' })}</p>
                <p className={styles.text}>{t('failed', { context: 'text_2' })}</p>
                <ul className={styles.list}>
                    <li className={styles.listItem}>{t('failed', { context: 'list_item_1' })}</li>
                    <li className={styles.listItem}>{t('failed', { context: 'list_item_2' })}</li>
                    <li className={styles.listItem}>{t('failed', { context: 'list_item_3' })}</li>
                </ul>
                <p className={styles.text}>{t('failed', { context: 'text_3' })}</p>
                <div className={styles.linkContainer}>
                    <Button
                        color={ButtonColor.GREEN_TRANSPARENT}
                        formatSize={ButtonFormatSize.LARGE}
                        link="https://t.me/Tvsignalscom_bot"
                    >
                        {t('write_to_support')}
                    </Button>
                </div>
            </PromoBlock>
        </div>
    );
};

export default FailedPaymentPage;
