import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { AppThunkDispatch } from '../../../../types/redux';
import DefaultInput from '../../../../components/defaultInput/DefaultInput';
import Button, { ButtonColor, ButtonType } from '../../../../components/button/Button';
import editUserInfo from '../../../../actions/user/editUserInfo';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../../../utils/logger';
import { User } from '../../../../types/api';
import { notifyError, notifySuccess } from '../../../../utils/notification';
import styles from './ProfileInfo.module.scss';

interface ProfileFormValues {
    fullName?: string;
    telegram?: string;
}

interface Props {
    user: User;
    className?: string;
}

const logger = createLogger('ProfileInfo', ACTIONS_LOG_LEVEL);

const schema = Yup.object().shape({});

const ProfileInfo: FC<Props> = ({ user, className }) => {
    const { t } = useTranslation('dashboard');
    const dispatch: AppThunkDispatch = useDispatch();

    const form = useForm<ProfileFormValues>({
        mode: 'onTouched',
        defaultValues: { ...user },
        resolver: yupResolver(schema)
    });

    const handleSubmit = useCallback(
        (values: ProfileFormValues) => {
            dispatch(
                editUserInfo({
                    email: user.email,
                    fullName: values.fullName,
                    telegram: values.telegram
                })
            )
                .then(() => {
                    dispatch(notifySuccess({ message: t('profile_tab.profile_change', { context: 'complete' }) }));
                })
                .catch((message) => {
                    dispatch(notifyError({ message: t('profile_tab.profile_change', { context: 'error' }) }));
                    Object.entries(message).map(([key, value]) => {
                        setError(key as any, { message: value as string });
                    });
                });
        },
        [user.email, dispatch]
    );

    const {
        register,
        setError,
        formState: { isSubmitting, isValid, errors },
        handleSubmit: onSubmit
    } = form;

    return (
        <form className={className} onSubmit={onSubmit(handleSubmit)}>
            <DefaultInput
                className={styles.input}
                placeholder={t('profile_tab.your_name')}
                inputStyle="black"
                aria-invalid={errors.fullName ? 'true' : 'false'}
                {...register('fullName')}
            />
            {errors.fullName && <p className={styles.error}>{errors.fullName.message}</p>}

            <DefaultInput
                className={styles.input}
                placeholder={t('profile_tab.telegram_id')}
                inputStyle="black"
                aria-invalid={errors.telegram ? 'true' : 'false'}
                {...register('telegram')}
            />
            {errors.telegram && <p className={styles.error}>{errors.telegram.message}</p>}

            <div className={styles.buttonBlock}>
                <Button
                    type={ButtonType.SUBMIT}
                    className={styles.button}
                    color={ButtonColor.TRANSPARENT}
                    disabled={isSubmitting || !isValid}
                >
                    {t('profile_tab.change_data')}
                </Button>
            </div>
        </form>
    );
};

export default ProfileInfo;
