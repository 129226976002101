import { castImmutable } from 'immer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Account } from '../../types/api';
import actionCreator from '../utils/actionCreator';
import immerCase from '../utils/immerCase';

export interface AccountState {
    accounts?: Account[];
}

const initialState = castImmutable<AccountState>({});

export const setAccounts = actionCreator<Account[] | undefined>('setAccounts');

// @ts-ignores
export default reducerWithInitialState<AccountState>(initialState).withHandling(
    immerCase(setAccounts, (draft, payload) => {
        draft.accounts = payload;
    })
);
