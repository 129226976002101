import { useState, useEffect } from 'react';
import getParsedLocalStorageValue, {
    setLocalStorageItem,
    getLocalStorageIsTrue
} from '../utils/localStorageUtils';

export enum ConsentType {
    BASIC = 'basic',
    ANALYTICS = 'analytics',
    ADVERTISING = 'advertising',
}

interface ConsentListProps {
    basic: boolean;
    analytics: boolean;
    advertising: boolean;
}

export interface UpdateConsentProps {
    newConsent: boolean;
    consentObject: ConsentListProps;
}

interface UseUserConsentProps {
    isLoaded: boolean;
    isUserConsentedCookies: boolean;
    consentList: ConsentType[];
    updateCookieConsent: (props: UpdateConsentProps) => void;
    resetConsent: () => void;
}

const COOKIE_CONSENT_KEY = 'accepted_cookies_v1';
const COOKIE_CONSENT_LIST_KEY = 'accepted_cookies_list_v1';

const useUserConsent = (): UseUserConsentProps => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasConsent, setHasConsent] = useState(false);
    const [consentList, setConsentList] = useState<ConsentType[]>([]);

    useEffect(() => {
        setHasConsent(getLocalStorageIsTrue(COOKIE_CONSENT_KEY));
        setConsentList(getParsedLocalStorageValue<ConsentType[]>(COOKIE_CONSENT_LIST_KEY) ?? []);

        setIsLoaded(true);
    }, []);

    const updateConsent = ({ newConsent, consentObject }: UpdateConsentProps) => {
        setHasConsent(newConsent);

        const newConsentList: ConsentType[] = [];
        if (consentObject.basic) {
            newConsentList.push(ConsentType.BASIC);
        }
        if (consentObject.analytics) {
            newConsentList.push(ConsentType.ANALYTICS);
        }
        if (consentObject.advertising) {
            newConsentList.push(ConsentType.ADVERTISING);
        }

        setConsentList(newConsentList);

        setLocalStorageItem(COOKIE_CONSENT_KEY, newConsent);
        setLocalStorageItem(COOKIE_CONSENT_LIST_KEY, newConsentList);
    };

    const resetConsent = () => {
        setHasConsent(false);
        setConsentList([]);

        localStorage.removeItem(COOKIE_CONSENT_KEY);
        localStorage.removeItem(COOKIE_CONSENT_LIST_KEY);
    };

    return {
        isLoaded,
        isUserConsentedCookies: hasConsent,
        consentList,
        updateCookieConsent: updateConsent,
        resetConsent,
    };
};

export default useUserConsent;
