import { useEffect, useState } from 'react';
import styles from './useLockScroll.module.scss';

function useLockScroll() {
    const [isScrollLocked, setIsScrollLocked] = useState(false);

    const toggleScrollLock = () => {
        setIsScrollLocked(!isScrollLocked);
    };

    useEffect(() => {
        const documentClassList = document.documentElement.classList;

        if (isScrollLocked) {
            documentClassList.add(styles.scrollLocked);
        } else {
            documentClassList.remove(styles.scrollLocked);
        }

        return () => {
            documentClassList.remove(styles.scrollLocked);
        };
    }, [isScrollLocked]);

    return { isScrollLocked, toggleScrollLock, setIsScrollLocked };
}

export default useLockScroll;
