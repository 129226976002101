import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import formatDate, { directDateMask, directDateMaskEn } from '../../utils/formatDate';
import { CURRENT_EMAIL_SUPPORT, LAST_PRIVACY_UPDATE } from '../../constants/companyData';
import MailLink from '../../components/mailLink/MailLink';
import PrivacyParagraph from './privacyParagraph/PrivacyParagraph';
import PrivacySubParagraph from './privacySubParagraph/PrivacySubParagraph';
import styles from './PrivacyPage.module.scss';

const PrivacyPage: FC = () => {
    const { t, i18n } = useTranslation('privacyPage');

    const lastPrivacyUpdate =
        i18n.language === 'ru'
            ? formatDate(LAST_PRIVACY_UPDATE, directDateMask)
            : formatDate(LAST_PRIVACY_UPDATE, directDateMaskEn);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('title')}</h2>
            <div className={styles.lastRefresh}>
                {t('last_updated')}
                {lastPrivacyUpdate}
            </div>
            <PrivacyParagraph title={t('clause', { context: 'title_1' })}>
                {t('clause', { context: 'content_1' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_2' })}>
                <PrivacySubParagraph title={t('clause', { context: 'title_2_1' })}>
                    {t('clause', { context: 'content_2_1' })}
                </PrivacySubParagraph>
                <PrivacySubParagraph title={t('clause', { context: 'title_2_2' })}>
                    {t('clause', { context: 'content_2_2' })}
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_3' })}>
                {t('clause', { context: 'content_3' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_4' })}>
                {t('clause', { context: 'content_4' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_5' })}>
                {t('clause', { context: 'content_5' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_6' })}>
                {t('clause', { context: 'content_6' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_7' })}>
                {t('clause', { context: 'content_7' })}
            </PrivacyParagraph>
            <PrivacyParagraph title={t('clause', { context: 'title_8' })}>
                {t('clause', { context: 'content_8' })} <MailLink mail={CURRENT_EMAIL_SUPPORT} />
            </PrivacyParagraph>
            <div className={styles.lastRefresh}>
                {t('last_updated')}
                {lastPrivacyUpdate}
            </div>
        </div>
    );
};

export default PrivacyPage;
