export enum Media {
    M = 640,
    L = 1240
}

export const mediaNamesMap: Record<Media, string> = {
    [Media.M]: 'M',
    [Media.L]: 'L'
};

export type Breakpoint = Media.M | Media.L;

export default Media;
