import React, { FC, PropsWithChildren } from 'react';
import { LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import InnerLink from '../innerLink/InnerLink';
import styles from './StyledLink.module.scss';

interface Props extends PropsWithChildren<LinkProps> {}

const StyledLink: FC<Props> = ({ children, className, ...otherProps }) => {
    return (
        <InnerLink {...otherProps} className={classNames(styles.link, className)}>
            {children}
        </InnerLink>
    );
};

export default StyledLink;
