import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from 'reapop';
import application, { ApplicationState } from './reducers/application';
import account, { AccountState } from './reducers/account';
import order, { OrderState } from './reducers/order';
import alert, { AlertState } from './reducers/alert';
import subscription, { SubscriptionState } from './reducers/subscription';

export interface ReduxState {
    notifications;
    application: ApplicationState;
    account: AccountState;
    order: OrderState;
    alert: AlertState;
    subscription: SubscriptionState;
}

export default combineReducers<ReduxState>({
    notifications: notificationsReducer(),
    application,
    account,
    order,
    alert,
    subscription
});
