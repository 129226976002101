import React, { ForwardedRef, forwardRef, FunctionComponent } from 'react';
import Select, { Props as ReactSelectProps, Theme } from 'react-select';
import classNames from 'classnames';
import { ReactComponent as DropdownArrow } from './dropdownArrow.svg';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import styles from './ForkedSelect.module.scss';
import { useTranslation } from 'react-i18next';

interface Props extends ReactSelectProps {
    className?: string;
}

const ForkedSelect: FunctionComponent<Props> = forwardRef(({ className, ...otherProps }, ref: ForwardedRef<any>) => {
    const { t } = useTranslation('common');

    return (
        <Select
            {...otherProps}
            ref={ref}
            theme={themeSelect}
            styles={jsStyles}
            placeholder={t('choose')}
            isSearchable={false}
            className={classNames(styles.selectContainer, className)}
            components={{
                DropdownIndicator: ({ isFocused, isDisabled }) => (
                    <div
                        className={classNames(styles.dropdownIndicator, {
                            [styles.dropdownIndicatorFocused]: isFocused,
                            [styles.dropdownIndicatorDisabled]: isDisabled
                        })}
                    >
                        <DropdownArrow />
                    </div>
                )
            }}
        />
    );
});

const themeSelect = (theme: Theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#0F8FEE'
    }
});

const jsStyles: StylesConfig = {
    control: (base, { isFocused }) => {
        const styles = {
            ...base,
            borderColor: 'var(--color-regular)',
            minHeight: 35,
            height: '100%',
            backgroundColor: 'var(--color-bg)',
            cursor: 'pointer',
            boxShadow: 'none'
        };

        if (isFocused) {
            return {
                ...styles,
                borderColor: 'var(--color-white)',
                '&:hover': {
                    borderColor: 'var(--color-white)'
                }
            };
        }

        return styles;
    },
    indicatorSeparator: (base, props) => ({
        ...base,
        display: 'none'
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '0 8px',
        fontSize: 16
    }),
    singleValue: (base) => ({
        ...base,
        color: 'inherit'
    }),
    option: (base, { isFocused, isSelected }) => {
        const styles = {
            ...base,
            fontSize: 16,
            cursor: 'pointer',
            color: 'var(--color-white)',
            transition: '.2s'
        };

        if (isFocused) {
            return { ...styles, backgroundColor: '#3a3a3a' };
        }

        if (isSelected) {
            return { ...styles, backgroundColor: '#434343' };
        }

        return styles;
    },
    menuList: (base) => ({
        ...base,
        padding: '0'
    }),
    menu: (base) => ({
        ...base,
        overflow: 'hidden',
        backgroundColor: 'var(--color-bg)',
        boxShadow: '0 0 0 1px hsla(0, 0%, 100%, 0.1), 0 4px 11px hsla(0, 0%, 100%, 0.1)'
    })
};

export default ForkedSelect;
