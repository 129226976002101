import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AccordionBlock, { AccordionItemData } from '../../components/accordionBlock/AccordionBlock';
import styles from './FaqPage.module.css';

const FaqPage: FC = () => {
    const { t } = useTranslation('homePage');

    const faqData: AccordionItemData[] = [
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_1.question')}</div>,
            content: <>{t('faq_block.item_1.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_2.question')}</div>,
            content: <>{t('faq_block.item_2.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_3.question')}</div>,
            content: <>{t('faq_block.item_3.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_4.question')}</div>,
            content: <>{t('faq_block.item_4.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_5.question')}</div>,
            content: <>{t('faq_block.item_5.answer')}</>
        },
        {
            title: <div className={styles.itemTitle}>{t('faq_block.item_6.question')}</div>,
            content: <>{t('faq_block.item_6.answer')}</>
        }
    ];

    return (
        <div>
            <AccordionBlock className={styles.items} data={faqData} />
        </div>
    );
};

export default FaqPage;
