import React, { FC } from 'react';
import styles from './AboutPage.module.css';

const AboutPage: FC = () => {
    return (
        <div className={styles.container}>
            <p><a href="/profile-test">Профиль</a></p>
        </div>
    );
};

export default AboutPage;
