import { FC } from 'react';
import StyledLink from '../styledLink/StyledLink';
import styles from './MailLink.module.scss';

interface Props {
    mail: string;
}

const MailLink: FC<Props> = ({ mail }) => {
    return (
        <StyledLink className={styles.link} to={`mailto:${mail}`}>
            {mail}
        </StyledLink>
    );
};

export default MailLink;
