import React, { FC, PropsWithChildren } from 'react';
import styles from './ProfileInstructionRow.module.scss';

interface Props extends PropsWithChildren {
    title: string;
    extra?: JSX.Element | string;
}

const ProfileInstructionRow: FC<Props> = ({ title, children, extra }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            {extra ? <div className={styles.extra}>{extra}</div> : null}
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default ProfileInstructionRow;
