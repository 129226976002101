import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsSystem, { dismissNotification, bootstrapTheme } from 'reapop';
import { ReduxState } from '../../redux/rootReducer';

const Notifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state: ReduxState) => state.notifications);

    return (
        <div>
            <NotificationsSystem
                notifications={notifications}
                dismissNotification={(id) => dispatch(dismissNotification(id))}
                theme={bootstrapTheme}
            />
        </div>
    );
};

export default Notifications;
