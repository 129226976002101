import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup, { PopupPosition, PopupSize, PopupStyle } from '../popup/Popup';
import AppRoute from '../../constants/AppRoute';
import Button, { ButtonColor, ButtonFormatSize } from '../button/Button';
import AccordionBlock, { AccordionItemData } from '../accordionBlock/AccordionBlock';
import InnerLink from '../innerLink/InnerLink';
import {
    AccordionHeaderPositions,
    AccordionItemSize,
    AccordionItemStyle
} from '../accordionBlock/accordionItem/AccordionItem';
import Switch from '../switch/Switch';
import { UpdateConsentProps } from '../../hooks/useUserConsent';
import styles from './CookiePopup.module.css';

interface Props {
    isUserConsentLoaded: boolean;
    isUserConsentedCookies: boolean;
    updateCookieConsent(props: UpdateConsentProps): void;
}

const CookiePopup: FC<Props> = ({ isUserConsentLoaded, isUserConsentedCookies, updateCookieConsent }) => {
    const { t } = useTranslation('cookie');
    const [isPopupShown, setIsPopupOpened] = useState(false);
    const [isCookieSettingsShown, setIsCookieSettingsShown] = useState(false);

    const [isBasicCookiesAccepted, setIsBasicCookiesAccepted] = useState(true);
    const [isAnalyticsCookiesAccepted, setIsAnalyticsCookiesAccepted] = useState(true);
    const [isAdvertisingCookiesAccepted, setIsAdvertisingCookiesAccepted] = useState(true);

    useEffect(() => {
        if (isUserConsentLoaded) {
            setIsPopupOpened(!isUserConsentedCookies);
        }
    }, [isUserConsentedCookies, isUserConsentLoaded]);

    const acceptAll = () => {
        updateCookieConsent({
            newConsent: true,
            consentObject: {
                basic: true,
                analytics: true,
                advertising: true
            }
        });
    };

    const acceptSelected = () => {
        updateCookieConsent({
            newConsent: true,
            consentObject: {
                basic: isBasicCookiesAccepted,
                analytics: isAnalyticsCookiesAccepted,
                advertising: isAdvertisingCookiesAccepted
            }
        });
    };

    const cookieSettingsShow = () => {
        setIsCookieSettingsShown(true);
    };

    const cookieSettingsHide = () => {
        setIsCookieSettingsShown(false);
    };

    const accordionItems: AccordionItemData[] = [
        {
            title: <div className={styles.accordionTitle}>{t('cookies', { context: 'basic' })}</div>,
            content: <>{t('cookies', { context: 'basic_details' })}</>,
            headerExtra: <Switch isEnabled={isBasicCookiesAccepted} onChange={setIsBasicCookiesAccepted} isLocked />
        },
        {
            title: <div className={styles.accordionTitle}>{t('cookies', { context: 'analytics' })}</div>,
            content: <>{t('cookies', { context: 'analytics_details' })}</>,
            headerExtra: <Switch isEnabled={isAnalyticsCookiesAccepted} onChange={setIsAnalyticsCookiesAccepted} />
        },
        {
            title: <div className={styles.accordionTitle}>{t('cookies', { context: 'advertising' })}</div>,
            content: <>{t('cookies', { context: 'advertising_details' })}</>,
            headerExtra: <Switch isEnabled={isAdvertisingCookiesAccepted} onChange={setIsAdvertisingCookiesAccepted} />
        }
    ];

    const shortPopupContent = (
        <>
            {t('short_agreement')}
            <InnerLink to={AppRoute.PRIVACY}>{t('short_agreement_private_policy')}</InnerLink>.
        </>
    );

    const settingsPopupContent = (
        <>
            <div className={styles.title}>{t('title')}</div>
            <div className={styles.entry}>{t('full_agreement_entry')}</div>
            <AccordionBlock
                data={accordionItems}
                size={AccordionItemSize.SMALL}
                className={styles.accordion}
                style={AccordionItemStyle.LIGHT}
                headerPositions={AccordionHeaderPositions.REVERSE}
            />
        </>
    );

    return (
        <Popup
            isOpened={isPopupShown}
            onTrigger={setIsPopupOpened}
            size={PopupSize.NARROW}
            position={PopupPosition.BOTTOM_RIGHT}
            style={PopupStyle.LIGHT}
            cardClassName={styles.container}
        >
            {isCookieSettingsShown ? settingsPopupContent : shortPopupContent}
            <div className={styles.buttonsContainer}>
                {isCookieSettingsShown ? (
                    <>
                        <Button
                            color={ButtonColor.BLUE_BORDERLESS}
                            onClick={acceptSelected}
                            formatSize={ButtonFormatSize.SMALL}
                        >
                            {t('save_settings')}
                        </Button>
                        <Button
                            color={ButtonColor.LINK}
                            onClick={cookieSettingsHide}
                            formatSize={ButtonFormatSize.SMALL}
                        >
                            {t('back')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            color={ButtonColor.BLUE_BORDERLESS}
                            onClick={acceptAll}
                            formatSize={ButtonFormatSize.SMALL}
                        >
                            {t('accept_all')}
                        </Button>
                        <Button
                            color={ButtonColor.LINK}
                            onClick={cookieSettingsShow}
                            formatSize={ButtonFormatSize.SMALL}
                        >
                            {t('set_cookies')}
                        </Button>
                    </>
                )}
            </div>
        </Popup>
    );
};

export default CookiePopup;
