import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AppRoute from '../../constants/AppRoute';
import ordersIcon from './images/orders.svg';
import profileIcon from './images/profile.svg';
import apiIcon from './images/key.svg';
import TabMenu from '../tabMenu/TabMenu';
import { TabItem } from '../tabMenu/tabMenuItem/TabMenuItem';
import styles from './ProfileMenu.module.scss';

const ProfileMenu: FC = () => {
    const { t } = useTranslation('dashboard');

    const menuItems: TabItem[] = [
        {
            link: AppRoute.PROFILE_ORDERS,
            iconLink: ordersIcon,
            title: t('orders')
        },
        {
            link: AppRoute.PROFILE_ALERTS,
            // TODO: обновить
            iconLink: ordersIcon,
            title: t('alerts')
        },
        {
            link: AppRoute.PROFILE,
            iconLink: profileIcon,
            title: t('profile')
        },
        {
            link: AppRoute.PROFILE_API,
            iconLink: apiIcon,
            title: t('api')
        }
    ];

    return <TabMenu className={styles.container} items={menuItems} autoActive={true} />;
};

export default ProfileMenu;
