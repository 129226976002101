import { FC, InputHTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export enum ButtonColor {
    GREEN = 'GREEN',
    GREEN_BORDERED = 'GREEN_BORDERED',
    BLUE = 'BLUE',
    BLUE_BORDERLESS = 'BLUE_BORDERLESS',
    TRANSPARENT = 'TRANSPARENT',
    GREEN_TRANSPARENT = 'GREEN_TRANSPARENT',
    LINK = 'LINK'
}

export enum ButtonFormatSize {
    SMALL = 'SMALL',
    REDUCED = 'REDUCED',
    DEFAULT = 'DEFAULT',
    LARGE = 'LARGE'
}

export enum ButtonType {
    BUTTON = 'button',
    RESET = 'reset',
    SUBMIT = 'submit'
}

interface Props extends PropsWithChildren<InputHTMLAttributes<HTMLButtonElement>> {
    link?: string;
    className?: string;
    color?: ButtonColor;
    formatSize?: ButtonFormatSize;
    type?: ButtonType;
    onClick?(): void;
}

const Button: FC<Props> = ({
    link,
    className,
    children,
    color = ButtonColor.GREEN,
    formatSize = ButtonFormatSize.DEFAULT,
    type,
    onClick,
    ...otherProps
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
            return;
        }
        if (link) {
            window.open(link, '_blank');
        }
    };

    return (
        <button
            className={classNames(
                styles.btn,
                {
                    [styles.green]: color === ButtonColor.GREEN,
                    [styles.greenBordered]: color === ButtonColor.GREEN_BORDERED,
                    [styles.blue]: color === ButtonColor.BLUE,
                    [styles.blueBorderless]: color === ButtonColor.BLUE_BORDERLESS,
                    [styles.transparent]: color === ButtonColor.TRANSPARENT,
                    [styles.greenTransparent]: color === ButtonColor.GREEN_TRANSPARENT,
                    [styles.linkColor]: color === ButtonColor.LINK
                },
                {
                    [styles.small]: formatSize === ButtonFormatSize.SMALL,
                    [styles.reduced]: formatSize === ButtonFormatSize.REDUCED,
                    [styles.large]: formatSize === ButtonFormatSize.LARGE
                },
                className
            )}
            type={type}
            onClick={handleClick}
            {...otherProps}
        >
            {children}
        </button>
    );
};

export default Button;
