import React, { FC } from 'react';
import MenuItem from '../menuItem/MenuItem';
import { HeaderRoute } from '../../../../types/app';
import getStringKey from '../../../../utils/getStringKey';

interface Props {
    routes: HeaderRoute[];
}

const HeaderMenu: FC<Props> = ({ routes }) => {
    return (
        <>
            {routes.map(({ to, href, title }, index) => (
                <MenuItem to={to} href={href} key={getStringKey(index, 'menuItem')}>
                    {title}
                </MenuItem>
            ))}
        </>
    );
};

export default HeaderMenu;
