import { FC, PropsWithChildren, useEffect, useState } from 'react';
import ScrollLink from '../scrollLink/ScrollLink';
import { HashLinkProps } from 'react-router-hash-link';

interface Props extends PropsWithChildren<HashLinkProps> {}

const InnerLink: FC<Props> = ({ to, className, children, ...otherProps }) => {
    const [processedLink, setProcessedLink] = useState(to);

    const setHash = (link: string) => {
        if (link.includes('#') || link.startsWith('mailto:')) {
            return link;
        }
        return `${link}#`;
    };

    useEffect(() => {
        let updatedLink: typeof to;

        if (typeof to === 'string') {
            updatedLink = setHash(to);
        } else if (to && typeof to.pathname === 'string') {
            updatedLink = { ...to, pathname: setHash(to.pathname) };
        } else {
            updatedLink = to;
        }

        setProcessedLink(updatedLink);
    }, [to]);

    return (
        <ScrollLink to={processedLink} className={className} {...otherProps}>
            {children}
        </ScrollLink>
    );
};

export default InnerLink;
