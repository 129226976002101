import React, { FC, PropsWithChildren } from 'react';
import { HashLink, HashLinkProps } from 'react-router-hash-link';
import useIsMedia from '../../hooks/useIsMedia';

interface Props extends PropsWithChildren<HashLinkProps> {
    className?: string;
}

const scrollWithOffset = (element: HTMLElement, yOffset = 0) => {
    const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const ScrollLink: FC<Props> = ({ to, className, children, ...otherProps }) => {
    const { isDesktop } = useIsMedia();

    return (
        <HashLink
            to={to}
            className={className}
            scroll={(el) => scrollWithOffset(el, isDesktop ? -130 : -75)}
            {...otherProps}
        >
            {children}
        </HashLink>
    );
};

export default ScrollLink;
