import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './HomePageTitle.module.scss';

interface Props extends PropsWithChildren {
    className?: string;
    superTitle?: string;
}

const HomePageTitle: FC<Props> = ({ className, children, superTitle }) => {
    return (
        <>
            {superTitle && <div className={styles.superTitle}>{superTitle}</div>}
            <h2 className={classNames(styles.title, className)}>{children}</h2>
        </>
    );
};

export default HomePageTitle;
