import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from '../../aliases/classNames';
import logoImg from '../../images/logo.svg';
import AuthFormLayout, { AuthFormLayoutTheme, LoginTab } from '../authFormLayout/AuthFormLayout';
import styles from './LoginRegLayout.module.scss';

interface Props extends PropsWithChildren {
    activeTab?: LoginTab;
    theme?: AuthFormLayoutTheme;
}

const LoginRegLayout: FunctionComponent<Props> = ({ children, activeTab, theme }) => {
    const { t } = useTranslation('loginPage');

    return (
        <div className={styles.container}>
            <div className={classNames(styles.logoColumn)}>
                <img className={styles.logoImg} src={logoImg} alt="" />
                <div className={styles.logoTitle}>{t('sub_logo')}</div>
            </div>
            <AuthFormLayout activeTab={activeTab} className={styles.formColumn} theme={theme}>
                {children}
            </AuthFormLayout>
        </div>
    );
};

export default LoginRegLayout;
