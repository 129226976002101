import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReduxState } from '../../../../redux/rootReducer';
import Button from '../../../../components/button/Button';
import { SubscriptionType } from '../../../../types/api';
import formatDate from '../../../../utils/formatDate';
import ProfileTitle from '../profileTitle/ProfileTitle';
import styles from './ProfileSubscription.module.scss';

interface Props {
    className?: string;
}

const ProfileSubscription: FC<Props> = ({ className }) => {
    const { t } = useTranslation('dashboard');
    const subscription = useSelector((state: ReduxState) => state.subscription.subscription);

    return (
        <>
            <ProfileTitle>
                {t('profile_tab.subscription_pre_title')}
                <span className={styles.blueText}>{subscription ? subscription.type : SubscriptionType.TRIAL}</span>
                {t('profile_tab.subscription_post_title')}
            </ProfileTitle>
            <div className={classNames(styles.tariffContent, className)}>
                {subscription ? (
                    <>
                        <div className={styles.tariffText}>{t('profile_tab.validity_period')}</div>
                        <div className={styles.daysLeft}>
                            {t('profile_tab.expiration_date')}
                            <span className={styles.blueText}>{formatDate(subscription.expiration)}</span>
                        </div>
                    </>
                ) : (
                    <div>
                        {t('profile_tab.current_trial_plan')}
                        <br />
                        {t('profile_tab.upgrade_pro')}
                    </div>
                )}
                <div className={styles.buttonBlock}>
                    {subscription ? (
                        <Button className={styles.button} disabled>
                            {t('profile_tab.change_tariff')}
                        </Button>
                    ) : (
                        <Button className={styles.button} link="https://t.me/Tvsignalscom_bot">
                            {t('profile_tab.upgrade_to_pro')}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProfileSubscription;
