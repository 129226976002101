import { ACCESS_TOKEN, post } from '../../utils/request';
import { Login } from '../../types/api';
import { setUser } from '../../redux/reducers/application';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';

const logger = createLogger('registration', ACTIONS_LOG_LEVEL);

const registration =
    (email: string, password: string, confirmPassword: string, fullName: string = '') =>
    async (dispatch) => {
        try {
            const response = await post<Login>({
                url: '/auth/signup',
                data: {
                    email,
                    fullName,
                    password,
                    confirmPassword
                }
            });
            localStorage.setItem(ACCESS_TOKEN, response.accessToken);
            return dispatch(setUser(response.user));
        } catch (error) {
            logger.error('catch error', error);
            dispatch(setUser(undefined));
            throw error;
        }
    };

export default registration;
