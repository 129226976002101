import { useState, useEffect } from 'react';
import Media from '../enums/Media';

const useScreenSize = () => {
    const [isS, setIsS] = useState(false);
    const [isM, setIsM] = useState(false);
    const [isL, setIsL] = useState(false);

    const handleResize = () => {
        const width = window.innerWidth;
        setIsS(width < Media.M);
        setIsM(width >= Media.M && width < Media.L);
        setIsL(width >= Media.L);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isMobile = isS || isM;
    const isDesktop = isL;

    return { isS, isM, isL, isMobile, isDesktop };
};

export default useScreenSize;
