import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './AdvantageItem.module.scss';

export interface Advantage {
    content: string | JSX.Element;
    iconLink: string;
}

interface Props {
    className?: string;
    data: Advantage;
}

const AdvantagesItem: FC<Props> = ({ data: { content, iconLink }, className }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.iconContainer}>
                <img className={styles.inlineLogo} src={iconLink} alt="" />
            </div>
            {content}
        </div>
    );
};

export default AdvantagesItem;
