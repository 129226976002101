import { AppThunkAction } from '../../types/redux';
import { request } from '../../utils/request';
import { Alert, Pageable } from '../../types/api';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';
import { SIZE_ELEMENTS, START_PAGE } from '../../constants/page';
import { setAlerts } from '../../redux/reducers/alert';

const logger = createLogger('getAlerts', ACTIONS_LOG_LEVEL);

export interface AlertsFilterParam {
    page?: number;
    size?: number;
}

const getAlerts =
    ({ page = START_PAGE, size = SIZE_ELEMENTS }: AlertsFilterParam = {}): AppThunkAction =>
    async (dispatch) => {
        try {
            const alerts = await request<Pageable<Alert>>({
                url: '/alerts/me/list',
                params: {
                    page,
                    size
                }
            });
            dispatch(setAlerts(alerts));
        } catch (error) {
            logger.error('catch error', error);
            dispatch(setAlerts(undefined));
        }
    };

export default getAlerts;
