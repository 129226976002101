import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENT_EMAIL_SUPPORT, CURRENT_FOOTER_YEAR } from '../../../../constants/companyData';
import MailLink from '../../../../components/mailLink/MailLink';
import StyledLink from '../../../../components/styledLink/StyledLink';
import AppRoute from '../../../../constants/AppRoute';
import Wrapper from '../../../../components/wrapper/Wrapper';
import styles from './Footer.module.scss';

const Footer: FC = () => {
    const { t } = useTranslation('common');

    return (
        <Wrapper className={styles.footer}>
            {t('copyright', { year: CURRENT_FOOTER_YEAR })}
            <div className={styles.footerPrivacy}>
                <MailLink mail={CURRENT_EMAIL_SUPPORT} />
                <StyledLink to={AppRoute.PRIVACY}>{t('privacy_policy')}</StyledLink>
            </div>
        </Wrapper>
    );
};

export default Footer;
