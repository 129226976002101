import { FunctionComponent, useState } from 'react';
import Pagination, { PaginationProps } from 'rc-pagination';
import ruLocale from 'rc-pagination/es/locale/ru_RU';
import enLocale from 'rc-pagination/es/locale/en_US';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button, { ButtonFormatSize } from '../button/Button';
import { ReactComponent as NextIcon } from './next.svg';
import styles from './ForkedPagination.module.scss';

interface Props extends PaginationProps {
    sizeDescription?: string;
    onChange(page: number, pageSize: number): void;
    className?: string;
}

const pageSizes = [10, 20, 50, 100];

const ForkedPagination: FunctionComponent<Props> = ({ sizeDescription, onChange, className, ...otherProps }) => {
    const { i18n } = useTranslation();
    const [pageSize, setPageSize] = useState<number>(pageSizes[0]);

    return (
        <Pagination
            className={classNames(className, styles.pagination)}
            locale={i18n.language.startsWith('ru') ? ruLocale : enLocale}
            pageSize={pageSize}
            prevIcon={
                <Button
                    formatSize={ButtonFormatSize.SMALL}
                    className={classNames(styles.rcPaginationItemLink, styles.prev)}
                >
                    <NextIcon />
                </Button>
            }
            nextIcon={
                <Button
                    formatSize={ButtonFormatSize.SMALL}
                    className={classNames(styles.rcPaginationItemLink, styles.next)}
                >
                    <NextIcon />
                </Button>
            }
            onShowSizeChange={onChange}
            onChange={onChange}
            {...otherProps}
        />
    );
};

export default ForkedPagination;
