import React, { FC } from 'react';
import TitleBlock from './components/titleBlock/TitleBlock';
import AdvantagesBlock from './components/advantagesBlock/AdvantagesBlock';
import CapabilitiesBlock from './components/capabilitiesBlock/CapabilitiesBlock';
import HowToStartBlock from './components/howToStartBlock/HowToStartBlock';
import TariffsBlock from './components/tariffsBlock/TariffsBlock';
import FaqBlock from './components/faqBlock/FaqBlock';
import styles from './HomePage.module.scss';

const HomePage: FC = () => {
    return (
        <div className={styles.container}>
            <TitleBlock />
            <AdvantagesBlock className={styles.advantageBlock} />
            <CapabilitiesBlock className={styles.block} />
            <HowToStartBlock className={styles.block} />
            <TariffsBlock className={styles.block} />
            <FaqBlock className={styles.block} />
        </div>
    );
};

export default HomePage;
