import Table from 'rc-table';
import { ColumnsType } from 'rc-table/lib/interface';
import { Order, Pageable } from '../../types/api';
import ForkedPagination from '../forkedPagination/ForkedPagination';
import { SIZE_ELEMENTS, START_PAGE } from '../../constants/page';
import EmptyPlaceholder from '../emptyPlaceholder/EmptyPlaceholder';
import { useTranslation } from 'react-i18next';
import styles from './ForkedTable.module.scss';

interface Props<T> {
    columns: ColumnsType<any>;
    pageable?: Pageable<T>;
    onChange(page: number, pageSize: number): void;
    indexColumn?: boolean;
}

const ForkedTable = <T extends {}>({
    columns: defaultColumns,
    pageable,
    onChange,
    indexColumn: isIndexColumn
}: Props<T>) => {
    const { t } = useTranslation('dashboard');

    const handleChange = (page: number, size: number) => {
        return onChange(page - 1, size);
    };

    const columns = isIndexColumn
        ? [
              {
                  title: t('table.header.number'),
                  dataIndex: 'index',
                  key: 'index',
                  width: 50,
                  render: (value: any, record: Order, index: number) => {
                      if (!pageable?.pageable) {
                          return value;
                      }
                      const { pageNumber = 0, pageSize = 0 } = pageable.pageable;
                      if (pageNumber === START_PAGE) {
                          return index + 1;
                      }
                      return index + 1 + pageSize * pageNumber;
                  },
                  className: styles.indexColumn
              },
              ...defaultColumns
          ]
        : defaultColumns;

    return (
        <Table
            rowKey="id"
            columns={columns}
            data={pageable?.content}
            className={styles.wrapper}
            emptyText={<EmptyPlaceholder className={styles.noDataContainer} />}
            summary={
                (pageable?.totalPages ?? 0) > 1
                    ? () => (
                          <tr>
                              <td colSpan={columns.length}>
                                  <ForkedPagination
                                      className={styles.pagination}
                                      pageSize={SIZE_ELEMENTS}
                                      current={(pageable?.pageable.pageNumber || 0) + 1}
                                      total={pageable?.totalElements}
                                      onChange={handleChange}
                                  />
                              </td>
                          </tr>
                      )
                    : undefined
            }
        />
    );
};

export default ForkedTable;
