import { put } from '../../utils/request';
import { Account } from '../../types/api';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';
import { setAccounts } from '../../redux/reducers/account';

const logger = createLogger('saveAccounts', ACTIONS_LOG_LEVEL);

const saveAccounts = (data) => async (dispatch) => {
    try {
        const accounts = await put<Account[]>({
            url: '/accounts/me/list',
            data
        });
        dispatch(setAccounts(accounts));
    } catch (error) {
        logger.error('catch error', error);
        throw error;
    }
};

export default saveAccounts;
