import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './CapabilityItem.module.scss';

interface Props extends PropsWithChildren {
    className?: string;
}

const CapabilityItem: FC<Props> = ({ children, className }) => {
    return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default CapabilityItem;
