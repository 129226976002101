import React, { FC } from 'react';
import classNames from 'classnames';
import InnerLink from '../../innerLink/InnerLink';
import styles from './TabMenuItem.module.scss';

export enum TabMenuItemTheme {
    LIGHT_BG = 'LIGHT_BG',
    DARK_BG = 'DARK_BG'
}

export enum TabMenuItemSize {
    DEFAULT = 'DEFAULT',
    SMALL = 'SMALL'
}

export interface TabItem {
    link: string;
    iconLink?: string;
    title: string | JSX.Element;
    isActive?: boolean;
    className?: string;
    size?: TabMenuItemSize;
    theme?: TabMenuItemTheme;
}

const TabMenuItem: FC<TabItem> = ({
    link,
    iconLink,
    isActive,
    title,
    className,
    size = TabMenuItemSize.DEFAULT,
    theme = TabMenuItemTheme.DARK_BG
}) => {
    return (
        <InnerLink
            to={link}
            className={classNames(
                styles.item,
                {
                    [styles.active]: isActive,
                    [styles.small]: size == TabMenuItemSize.SMALL,
                    [styles.lightTheme]: theme == TabMenuItemTheme.LIGHT_BG
                },
                className
            )}
        >
            {iconLink ? <img className={styles.icon} src={iconLink} alt="" /> : null}
            {title}
        </InnerLink>
    );
};

export default TabMenuItem;
