import axios, { AxiosRequestConfig } from 'axios';
import { API_HOST } from '../constants/env';
import { createLogger, UTILS_LOG_LEVEL } from './logger';
import { ApiCode } from '../types/api';

export const ACCESS_TOKEN = 'accessToken';

const requestLogger = createLogger('requestLogger', UTILS_LOG_LEVEL);
const axiosInstance = axios.create({
    baseURL: API_HOST,
    timeout: 10 * 1000
});

export const request = async <T>(params: AxiosRequestConfig): Promise<T> => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    try {
        return axiosInstance
            .request<T>({
                ...params,
                headers: {
                    ...params.headers,
                    ...(token ? { Authorization: `Bearer ${token}` } : {})
                }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                if (token && error?.response.status === ApiCode.NOT_AUTHORIZED) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    window.location.reload();
                }

                if (error?.response?.data) {
                    throw error.response.data;
                }
                throw error;
            });
    } catch (error) {
        requestLogger.error(`catch error while requesting ${params.method} ${params.url}`, error);
        throw error;
    }
};

export type RequestConfig = Omit<AxiosRequestConfig, 'withCredentials'>;

export const post = async <T>(params: Omit<RequestConfig, 'method'>) => request<T>({ ...params, method: 'POST' });
export const put = async <T>(params: Omit<RequestConfig, 'method'>) => request<T>({ ...params, method: 'PUT' });
export const remove = async <T>(params: Omit<RequestConfig, 'method'>) => request<T>({ ...params, method: 'DELETE' });
