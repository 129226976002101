import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Switch.module.scss';

interface Props {
    isEnabled: boolean;
    onChange(value: boolean);
    isLocked?: boolean;
}

const Switch: FC<Props> = ({ isEnabled, onChange, isLocked }) => {
    const handleChangeValue = () => {
        if (!isLocked) {
            onChange(!isEnabled);
        }
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.enabled]: isEnabled,
                [styles.locked]: isLocked
            })}
            onClick={handleChangeValue}
        >
            <div className={classNames(styles.toggle)}></div>
        </div>
    );
};

export default Switch;
