import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import logoImg from '../../../../images/logo.svg';
import HomePageButton from '../homePageButton/HomePageButton';
import PromoBlock from '../../../../components/promoBlock/PromoBlock';
import styles from './TitleBlock.module.scss';

const TitleBlock: FC = () => {
    const { t } = useTranslation('homePage');

    return (
        <PromoBlock>
            <h1 className={styles.title}>
                {t('title_block.title_h1_part_1')}
                <img className={styles.inlineLogo} src={logoImg} alt="" />
                {t('title_block.title_h1_part_2')}
            </h1>
            <p className={styles.text}>{t('title_block.text')}</p>
            <div className={styles.buttonBlock}>
                <HomePageButton />
            </div>
        </PromoBlock>
    );
};

export default TitleBlock;
