import { ACTIONS_LOG_LEVEL, createLogger } from './logger';

const logger = createLogger('localStorageUtils', ACTIONS_LOG_LEVEL);

export const setLocalStorageItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getParsedLocalStorageValue = <T>(key: string) => {
    try {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data) as T;
        } else {
            return null;
        }
    } catch (error) {
        logger.error(`An error occurred while getting localStorage data for key "${key}":`, error);
        return null;
    }
};

export const getLocalStorageBoolean = (key: string) => {
    return getParsedLocalStorageValue<boolean>(key);
};

export const getLocalStorageIsTrue = (key: string) => {
    return getLocalStorageBoolean(key) === true;
};

export default getParsedLocalStorageValue;
