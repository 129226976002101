import { castImmutable } from 'immer';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { User } from '../../types/api';
import actionCreator from '../utils/actionCreator';
import immerCase from '../utils/immerCase';

export interface ApplicationState {
    user?: User;
    isUserInitialized?: boolean;
}

const initialState = castImmutable<ApplicationState>({});

export const setUser = actionCreator<User | undefined>('setUser');

export default reducerWithInitialState<ApplicationState>(initialState).withHandling(
    immerCase(setUser, (draft, payload) => {
        draft.isUserInitialized = Boolean(payload);
        draft.user = payload;
    })
);
